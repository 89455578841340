import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from '../ButtonComponent/ButtonComponent';
import { ConfirmationDialog } from '../Dialogs';

import { SaveButtonContainer } from './style';

export const SettingsForm = ({ isSaveButtonVisible, handleSave, id, children, refForm, confirmMessage }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { t } = useTranslation();

  const handleToggleDialog = () => setOpenConfirmation(prevValue => !prevValue);

  const handleConfirm = () => {
    setOpenConfirmation(false);
    handleSave();
  };
  const handleSubmit = e => {
    if (id !== e.target.id) return;

    confirmMessage ? handleToggleDialog() : handleSave(e);
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} id={id} ref={refForm} noValidate style={{ position: "relative" }}>
      <Grid container>
        {children}
        <SaveButtonContainer item xs={4}>
          {isSaveButtonVisible && <ButtonComponent type="submit" text={t('common.buttons.saveChanges')} />}
        </SaveButtonContainer>
      </Grid>
      <ConfirmationDialog
        open={openConfirmation}
        message={confirmMessage}
        handleYes={handleConfirm}
        handleNo={handleToggleDialog}
        confirmText={t('common.buttons.save')}
      />
    </ValidatorForm>
  );
};

SettingsForm.propTypes = {
  id: PropTypes.string,
  isSaveButtonVisible: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  refForm: PropTypes.shape({}),
  confirmMessage: PropTypes.string,
};

SettingsForm.defaultProps = {
  id: '',
  refForm: null,
  confirmMessage: '',
};
