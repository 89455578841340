import {
  Grid as MuiGrid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Backdrop as MuiBackdrop,
  withStyles,
} from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Grid = withStyles({
  root: {
    marginBottom: '24px',
    '&.tags-card': { backgroundColor: COLORS.white },
    '&.tags-card > div': { padding: '20px 20px 0 20px' },
    '&.mb-0': { marginBottom: 0 },
    '&.error > div': { border: `1px solid ${COLORS.red}`, backgroundColor: COLORS.redLight },
    '&.animated > div': {
      background: COLORS.orangeLight,
      border: `1px solid ${COLORS.orange}`,
      transition: 'border 2s, background 2s',
      opacity: '1',
    },
    '&.stop-animate > div': { transition: 'border 2s, background 2s' },
    '&.hintBlock > div': {
      padding: '20px 28px 0 28px',
      borderRadius: '6px',
      border: `solid 1px ${COLORS.orange}`,
      background: 'rgba(255, 122, 3, 0.15)',
      color: COLORS.orange,
    },
    '&.cloneStatusDialog': {
      width: '100%',
      position: 'fixed',
      left: '50%',
      bottom: '0',
      transform: 'translateX(-50%)',
      zIndex: 1,
    },
    '&.cloneStatusDialog > div': {
      padding: '12px 20px',
    },
  },
})(MuiGrid);

export const Card = withStyles({
  root: {
    border: `solid 1px ${COLORS.greyBorder}`,
    borderRadius: '8px',
    padding: '28px 28px 0 28px',
    position: 'relative',
  },
})(MuiCard);

export const CardContent = withStyles(theme => ({
  root: {
    ...theme.typography.caption,
    padding: '0',
    marginBottom: '4px',
    '&.titleWrapper': { display: 'flex' },
    '&.mb': { marginBottom: '20px' },
    '&.hintContent': {
      marginBottom: 0,
      '&:last-child': {
        paddingBottom: '20px',
      },
    },
    '&.cloneStatusContent': {
      paddingBottom: 0,
    },
  },
}))(MuiCardContent);

export const Typography = withStyles({
  root: {
    textTransform: 'uppercase',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.black,
    '&.loadingText': {
      marginTop: '24px',
      textTransform: 'unset',
    },
    '&.inherit': {
      margin: 0,
      textTransform: 'unset',
      color: 'inherit',
    }
  },
})(MuiTypography);

export const Backdrop = withStyles({
  root: {
    position: 'absolute',
    backgroundColor: 'rgba(238,238,238,.5)',
    backdropFilter: 'blur(3px)',
    zIndex: 100,
    'user-select': 'none',
  },
})(MuiBackdrop);
