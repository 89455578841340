import React, { ChangeEvent } from 'react';

import { Grid, Box, Typography, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';
import { COLORS } from 'theme/constants';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { CloseIcon, IFrameCaption } from './style';

interface Settings {
  primaryColor: string;
  secondaryColor: string;
}

interface OverlayCloseButtonSettingsProps {
  settings: Settings;
  onChange: (settings: Settings) => void;
  showPreview?: boolean;
}

export const OverlayCloseButtonSettings = ({
  settings,
  onChange,
  showPreview = false,
}: OverlayCloseButtonSettingsProps) => {
  const { t } = useTranslation();
  const { primaryColor, secondaryColor } = settings;

  const handleCheckedColorChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    onChange({ ...settings, secondaryColor: checked ? '' : COLORS.white });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={showPreview ? 7 : 12}>
          <Box mb={4}>
            <Typography variant="h4" display="inline">
              Close Overlay Icon
            </Typography>
          </Box>
          <Box>
            <IFrameCaption>{t('components.iframeColorComponent.message')}</IFrameCaption>
          </Box>
          <Box mt={5}>
            <Box mb={2}>
              <Typography variant="caption">{t('components.iframeColorComponent.primaryColorHelperText')}</Typography>
            </Box>
            <ColorPicker
              setColor={({ hex }) => onChange({ ...settings, primaryColor: hex })}
              color={{ hex: primaryColor }}
            />
          </Box>
          <Box mt={5}>
            <Box mb={2}>
              <Typography variant="caption">{t('components.iframeColorComponent.secondaryColorHelpText')}</Typography>
            </Box>
            <ColorPicker
              setColor={({ hex }) => onChange({ ...settings, secondaryColor: hex })}
              color={{ hex: secondaryColor }}
            />
          </Box>
          <Box ml={7}>
            <Typography variant="caption">
              <Checkbox color="primary" checked={secondaryColor === ''} onChange={handleCheckedColorChange} />
              <span>{t('components.iframeColorComponent.checkboxHelpText')}</span>
            </Typography>
          </Box>
        </Grid>
        {showPreview && (
          <Grid item sm={5}>
            <Grid item sm={12}>
              <Box mb={4}>
                <Typography variant="h4" display="inline">
                  {t('dialogs.fontSettingsEdit.preview')}
                </Typography>
              </Box>
            </Grid>
            <ButtonPreviewWrapper>
              <CloseIcon
                className="btn-wrap regular"
                style={{
                  borderColor: primaryColor,
                  backgroundColor: secondaryColor,
                }}
              >
                <span style={{ color: primaryColor }} />
              </CloseIcon>
            </ButtonPreviewWrapper>
          </Grid>
        )}
      </Grid>
    </>
  );
};
