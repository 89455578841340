import React from 'react';

import { Box, Divider, Grid, LinearProgress, Typography } from '@material-ui/core';

import { ButtonComponent, ColoredBox } from 'components';
import { useTimeoutUpdate } from 'hooks';
import { shoppingModel } from 'models';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { getTWithPathKey } from 'utils';
import { ClientShoppingProvider, ShoppingProviderStatus } from 'types';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';

const inProgressStatus = [ShoppingProviderStatus.inProgress, ShoppingProviderStatus.disconnectInProgress];

interface SyncStatusProps {
  providerName: string;
  providerId: number;
  clientShoppingProvider: ClientShoppingProvider;
  getShoppingProvider: () => Promise<void>;
  handleServerError: (e: ServerErrorType) => void;
  handleToggleDialog: () => void;
}

export function SyncStatusComponent({
  providerName,
  providerId,
  clientShoppingProvider,
  getShoppingProvider,
  handleServerError,
  handleToggleDialog,
}: SyncStatusProps) {
  const getT = getTWithPathKey('pages.ecommerceProvider.sync');

  const handleSync = () =>
    shoppingModel
      .startSynchronization(providerId, clientShoppingProvider.id!)
      .then(getShoppingProvider)
      .catch(handleServerError);

  useTimeoutUpdate(
    inProgressStatus.includes(clientShoppingProvider.syncStatus!),
    getShoppingProvider,
    clientShoppingProvider,
  );

  const renderSyncStatus = () => {
    switch (clientShoppingProvider.syncStatus) {
      case ShoppingProviderStatus.success:
        return (
          <Grid item sm={4}>
            <Box display="flex" ml={5} mt={5}>
              <StatusComponent status={clientShoppingProvider.syncStatus} statusText="Completed" />
            </Box>
          </Grid>
        );
      case ShoppingProviderStatus.inProgress:
        return (
          <Grid item sm={12}>
            <Box my={5}>
              <LinearProgress color="primary" />
            </Box>
          </Grid>
        );
      case ShoppingProviderStatus.failed:
        return (
          <Grid item sm={8}>
            <Box mt={5}>
              <ColoredBox variant="error">
                <Box my={3} mx={2}>
                  <Typography>{getT('error')}</Typography>
                </Box>
              </ColoredBox>
            </Box>
          </Grid>
        );
      default:
        return (
          <Grid item sm={12}>
            <Box mt={6}>
              <ButtonComponent onClick={handleSync} text={getT('startButton')} />
            </Box>
          </Grid>
        );
    }
  };

  return (
    <>
      <Grid item sm={12}>
        <Box mt={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box mt={8}>
          <Typography variant="h2" className="uppercase">
            {getT('title')}
          </Typography>
        </Box>
      </Grid>
      {clientShoppingProvider.syncStatus !== ShoppingProviderStatus.inProgress &&
        clientShoppingProvider.syncStatus !== ShoppingProviderStatus.idle &&
        clientShoppingProvider.syncStatus !== null && (
        <Grid item sm={8}>
          <Box mt={5}>
            <Typography className="information">{getT('caption')}</Typography>
          </Box>
          <Box mt={5}>
            <ButtonComponent text={getT('dialogTitle')} onClick={handleToggleDialog} />
          </Box>
        </Grid>
      )}
      {renderSyncStatus()}
    </>
  );
}
