import React, { memo, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';

import { CardComponent, DotsComponent } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { NewTag } from 'components/Dialogs';
import { tagsModel } from 'models';
import { TagListTypes } from 'types/enums';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

import { Button, Box, Typography, AddIcon, EditIcon } from './style';

export function TagsListComponent({
  handleSelect,
  activeTag,
  handleServerError,
  resetServerErrors,
  shouldUpdateTags,
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
  addImageToTag,
  actionsAfterAddingAsset,
  allAssetsText,
  pageType,
  untaggedAssets,
}) {
  const [openCreateTagDialog, setOpenCreateTagDialog] = useState(false);
  const [onDragTagId, setOnDragTagId] = useState(null);
  const [tagToEdit, setTagToEdit] = useState(undefined);
  const { withPageProgressHandler } = useBusyProgressActions();

  const [tags, setTags] = useState([]);

  useEffect(() => {
    getTags();
  }, [paginationParams]);

  useEffect(() => {
    setOnDragTagId(null);
    getTags();
  }, [shouldUpdateTags]);

  const getTags = withPageProgressHandler(() =>
    tagsModel
      .getTagsWithOrder({ ...paginationParams })
      .then(({ tags, tagsCount }) => {
        setTags(tags);
        setPaginationItemsCount(tagsCount);
      })
      .catch(handleServerError)
  );

  const handleCreate = () => setOpenCreateTagDialog(true);

  const afterAction = tag => {
    if (!isEmpty(tag) && openCreateTagDialog) {
      resetPaginationPage();
    }
    setOpenCreateTagDialog(false);
    resetServerErrors();
    setTagToEdit(undefined);
    actionsAfterAddingAsset();
    getTags();
  };

  const customDrop = tagId => event => {
    const pictureId = event.dataTransfer.getData('pictureId');

    addImageToTag(tagId, [pictureId]);
  };

  const handleDragOver = tagId => event => {
    event.preventDefault();
    setOnDragTagId(tagId);
  };

  const handleDragEnd = () => () => setOnDragTagId(null);

  const setColorClassName = tagId => (onDragTagId === tagId ? 'focus' : '');

  const getActiveClass = condition => (condition ? 'active' : '');

  const handleEditTag = (e, tag) => {
    e.stopPropagation();

    setTagToEdit(tag);
    handleCreate();
  };

  const renderTags = () =>
    tags.map(tag => {
      let itemsCount;
      const { id, pictureIds, videoIds, taggingRecordsCount, name, color } = tag;

      if (pageType === TagListTypes.VIDEOS) {
        itemsCount = videoIds.length;
      } else if (pageType === TagListTypes.IMAGES) {
        itemsCount = pictureIds.length;
      } else if (pageType === TagListTypes.TAGS) {
        itemsCount = taggingRecordsCount;
      }

      return (
        <Box className="tags-item" key={id}>
          <Button
            className={`${getActiveClass(id === activeTag.id)} ${setColorClassName(id)}`}
            onDragLeave={handleDragEnd(id)}
            onDragOver={handleDragOver(id)}
            onDrop={customDrop(id)}
            onClick={handleSelect(tag)}
            variant="contained"
            size="small"
            mr={2}
            fullWidth
          >
            <Typography className="count">{itemsCount}</Typography>
            <Typography className="name">{name}</Typography>
            <DotsComponent dotsColors={[color]} />
            <EditIcon onClick={event => handleEditTag(event, tag)} />
          </Button>
        </Box>
      );
    });

  return (
    <CardComponent wrapperClassName="tags-card" sm={12}>
      <Box className="tags-wrapper">
        <Box className="tags-item">
          <Button
            className={getActiveClass(activeTag?.id === undefined && activeTag?.untagged === false)}
            onClick={handleSelect({ id: undefined, untagged: false })}
            variant="contained"
            size="small"
            id={0}
            mr={2}
            fullWidth
          >
            <Typography variant="h4">{allAssetsText}</Typography>
          </Button>
        </Box>
        <Box className="tags-item">
          <Button className="flex ml-auto without-shadow" color="primary" onClick={handleCreate} size="small" id="tags" mr={2}>
            <AddIcon color="primary" />
            <Typography variant="h4">Add Tag</Typography>
          </Button>
        </Box>
        <Box className="tags-item" key="untagged">
          <Button
            className={`${getActiveClass(activeTag.id === undefined && activeTag.untagged === true)} untagged`}
            onClick={handleSelect({ id: undefined, untagged: true })}
            variant="contained"
            size="small"
            mr={2}
            fullWidth
          >
            <Typography className="count">{untaggedAssets}</Typography>
            <Typography className="name">Untagged</Typography>
            <DotsComponent dotsColors={['transparent']} style={{ border: '1px solid #b7b7ba' }} />
          </Button>
        </Box>
        {renderTags()}
        {openCreateTagDialog &&
          <NewTag tag={tagToEdit} afterAction={afterAction} handleServerError={handleServerError} />
        }
      </Box>
    </CardComponent>
  );
}

TagsListComponent.propTypes = {
  ...withPaginationPropTypes,
  handleSelect: PropTypes.func.isRequired,
  activeTag: PropTypes.shape(
    {
      id: PropTypes.number,
      untagged: PropTypes.bool
    }
  ),
  handleServerError: PropTypes.func.isRequired,
  resetServerErrors: PropTypes.func.isRequired,
  shouldUpdateTags: PropTypes.bool.isRequired,
  addImageToTag: PropTypes.func.isRequired,
  actionsAfterAddingAsset: PropTypes.func,
  allAssetsText: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  untaggedAssets: PropTypes.number,
};

TagsListComponent.defaultProps = {
  activeTag: { id: undefined, untagged: false },
  pageType: TagListTypes.TAGS,
};

export const TagsList = compose(withPagination, memo)(TagsListComponent);
