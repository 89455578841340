import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import { PageHeader } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { TagListTypes } from 'types/enums';
import { tagsModel } from 'models';

import { ImagesList } from '../ImagesList/ImagesList';
import { TagsList } from '../TagsList/TagsList';

export default function ManageImages() {
  const [activeTag, setActiveTag] = useState({ id: undefined, untagged: false });
  const [shouldUpdateTags, setShouldUpdateTags] = useState(false);
  const [shouldUpdatePictures, setShouldUpdatePictures] = useState(false);
  const [imagesCount, setImageCount] = useState(0);
  const [untaggedAssetsCount, setUntaggedAssetsCount] = useState(0);

  const { handleServerError, resetServerErrors } = useServerErrorActions();

  const handleUpdateTags = () => setShouldUpdateTags(prevState => !prevState);
  const handleUpdateImages = () => setShouldUpdatePictures(prevState => !prevState);

  const handleSelectTag = tag => () => {
    if (tag.id !== activeTag.id || tag?.untagged !== activeTag?.untagged) {
      setActiveTag(tag);
    }
  };

  const addImageToTag = (tagId, pictureIds) =>
    tagsModel
      .attachAssetsToTag({
        tag: {
          pictureIds,
        },
        id: tagId,
      })
      .catch(error => handleServerError(error))
      .finally(() => {
        handleUpdateTags();
        handleUpdateImages();
      });

  return (
    <>
      <PageHeader title={`Images: ${imagesCount}`} />
      <Grid container spacing={6} wrap="nowrap">
        <Grid item xs={12} sm={3} style={{ minWidth: '276px' }}>
          <TagsList
            activeTag={activeTag}
            handleSelect={handleSelectTag}
            untaggedAssets={untaggedAssetsCount}
            shouldUpdateTags={shouldUpdateTags}
            handleServerError={handleServerError}
            resetServerErrors={resetServerErrors}
            actionsAfterAddingAsset={handleUpdateImages}
            addImageToTag={addImageToTag}
            allAssetsText="All Images"
            pageType={TagListTypes.IMAGES}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <ImagesList
            activeTag={activeTag}
            handleUpdateTags={handleUpdateTags}
            shouldUpdatePictures={shouldUpdatePictures}
            setImageCount={setImageCount}
            setUntaggedAssetsCount={setUntaggedAssetsCount}
            handleServerError={handleServerError}
            addImageToTag={addImageToTag}
          />
        </Grid>
      </Grid>
    </>
  );
}
