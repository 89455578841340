import {
  ClientShoppingProvider,
  NotificationItem,
  RedirectDelayParams,
  ShoppingProvider,
  ShoppingProviderItem,
  ShoppingProviderStatus,
} from 'types';

import { api } from './setupApi';

function handleIdleSyncStatus(data: ShoppingProvider) {
  if (data.clientShoppingProvider?.syncStatus === null)
    data.clientShoppingProvider.syncStatus = ShoppingProviderStatus.idle;
  return data;
}

class ShoppingModel {
  link = '/shopping_providers';

  linkClient = '/client_shopping_providers';

  linkStartSynchronization = '/start_synchronization';

  linkStartReSynchronization = '/start_resynchronization';

  linkMessage = '/notification_messages';

  linkDisconnect = '/disconnect';

  linkSetRedirectDelay = '/set_redirect_delay';

  getShoppingProviders = (): Promise<ShoppingProviderItem[]> =>
    api.get<ShoppingProviderItem[]>(this.link).then(({ data }) => data);

  getShoppingProvider = (id: number): Promise<ShoppingProvider> =>
    api.get<ShoppingProvider>(`${this.link}/${id}`).then(({ data }) => handleIdleSyncStatus(data));

  postShoppingProvider = (id: number | string, clientShoppingProvider: ClientShoppingProvider) =>
    api.post(`${this.link}/${id}${this.linkClient}`, { clientShoppingProvider }).then(({ data }) => data);

  putShoppingProvider = (id: number | string, { id: providerId, ...clientShoppingProvider }: ClientShoppingProvider) =>
    api.put(`${this.link}/${id}${this.linkClient}/${providerId}`, { clientShoppingProvider }).then(({ data }) => data);

  deleteShoppingProvider = (id: number | string, providerId: number | string) =>
    api.delete(`${this.link}/${id}${this.linkClient}/${providerId}`).then(({ data }) => data);

  startSynchronization = (providerId: number, clientProviderId: number | string) =>
    api
      .get(
        // eslint-disable-next-line max-len
        `${this.link}/${providerId}${this.linkClient}/${clientProviderId}${this.linkStartSynchronization}`,
      )
      .then(({ data }) => data);

  startReSynchronization = (providerId: number, clientProviderId: number | string, params = {}) =>
    api
      .post(
        // eslint-disable-next-line max-len
        `${this.link}/${providerId}${this.linkClient}/${clientProviderId}${this.linkStartReSynchronization}`,
        params,
      )
      .then(({ data }) => data);

  getMessagesLog = (params = {}): Promise<NotificationItem[]> =>
    api.get<NotificationItem[]>(this.linkMessage, { params }).then(({ data }) => data);

  disconnectStore = (id: number | string, providerId: number | string, spaceName: string) =>
    api
      .post(`${this.link}/${id}${this.linkClient}/${providerId}${this.linkDisconnect}`, {
        clientShoppingProvider: { spaceName },
      })
      .then(({ data }) => data);

  setRedirectDelay = (id: number | string, providerId: number, params: RedirectDelayParams) =>
    api
      .put(`${this.link}/${id}${this.linkClient}/${providerId}${this.linkSetRedirectDelay}`, params)
      .then(({ data }) => data);
}

export const shoppingModel = new ShoppingModel();
