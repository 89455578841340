import React, { useEffect, useState } from 'react';

import { Box, Typography, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TagAssets } from 'components/TagAssets/TagAssets';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { tagsModel } from 'models';
import { Tag } from 'types';
import { normalizeServerError } from 'utils';
import { getRandomColor } from 'utils/getRandomColor';

import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '../style';

const defaultTag: Tag = {
  id: undefined,
  name: '',
};

const assetPaginationParams = {
  ...DEFAULT_TABLE_PARAMS,
  perPage: 16,
}

interface TagProps {
  tag?: Tag | undefined;
  isTagPage?: boolean;
  afterAction: (edited: boolean) => void;
  message?: string;
  handleServerError: (err: any) => void;
}

export const NewTag = ({
  afterAction,
  tag,
  isTagPage = false,
  message,
  handleServerError,
}: TagProps) => {
  const [state, setState] = useState({
    tag: tag ?? { ...defaultTag, color: getRandomColor() },
    open: true,
    message: '',
    errorMessages: {},
    isLoading: false
  });
  const [showDeleteTagDialog, setShowDeleteTagDialog] = useState(false);
  const { t } = useTranslation();
  const gridLayout = isTagPage ? 6 : 12;
  const itemSpacing = isTagPage ? 6 : 0;
  const marginTop = isTagPage ? 2 : 0;

  useEffect(() => (
    setState(prevState => ({
      ...prevState,
      isLoading: false
    }))
  ), []);

  const submitForm = () => {
    setState(oldState => ({ ...oldState, request: true, isLoading: true }));
    const { name, color } = state.tag;

    const tagPromise = state.tag?.id ?
      tagsModel.editTag(state.tag.id, { name, color }) :
      tagsModel.addTag(name, color ?? getRandomColor());

    return tagPromise
      .then(() => {
        afterAction(!!state.tag?.id);
        setState(prevState => ({
          ...prevState,
          tag: { ...defaultTag, color: getRandomColor()}
        }));
      })
      .catch(err => {
        handleServerError(normalizeServerError(err));
      })
      .finally(() => {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }))
      });
  };

  const handleClose = () => {
    setState(oldStates => ({ ...oldStates, open: false }));
    afterAction(false);
  };

  const handleChange = (item: Record<string, string>) =>
    setState(prevState => ({
      ...prevState,
      tag: { ...prevState.tag, ...item },
    }));

  const handleDeleteTag = () => {
    if (!state.tag.id) {
      return;
    }

    tagsModel.deleteTag(state.tag.id)
      .then(() => {
        afterAction(Boolean(state.tag?.id));
      })
      .catch(handleServerError);
  };

  const getMessage = () => {
    if (message) {
      return message;
    }

    return state.tag !== undefined ? 'Edit tag' : 'Add new tag';
  };

  return (
    <>
      <Dialog className={isTagPage ? 'tag-assets' : ''} open={state.open} onClose={handleClose} fullWidth>
        <DialogTitle className="pb-0">{getMessage()}</DialogTitle>
        <ValidatorForm onSubmit={submitForm}>
          <DialogContent>
            <Grid container spacing={itemSpacing}>
              <Grid item sm={gridLayout}>
                <Box mb={4}>
                  <TextValidatorComponent
                    validators={[VALIDATORS_MAP.required]}
                    label="Name"
                    value={state.tag.name}
                    fieldName="name"
                    handleChange={handleChange}
                    autoFocus
                    disableMinHeight
                  />
                </Box>
              </Grid>
              <Grid item sm={gridLayout}>
                <Box display="flex" alignItems="center" mb={5} mt={marginTop}>
                  <ColorPicker
                    setColor={color => handleChange({ color: color.hex })}
                    color={{ hex: state.tag?.color }}
                  />
                  <Box ml={5}>
                    <Typography>Tag color</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box>
              {isTagPage &&
                <Box mt={-4} mb={5}>
                  <TagAssets
                    initialPaginationParams={assetPaginationParams}
                    tag={state.tag}
                    handleServerError={handleServerError}
                  />
                </Box>
              }
            </Box>
          </DialogContent>
          <DialogActions>
            {!isEmpty(state.tag) && state.tag.id && (
              <ButtonComponent
                onClick={() => setShowDeleteTagDialog(true)}
                loaderSize="1.35em"
                text="Delete tag"
                className="left error"
              />
            )}
            <ButtonComponent onClick={handleClose} text="Cancel" />
            <ButtonComponent
              type="submit"
              color="primary"
              loaderSize="1.35em"
              loading={state.isLoading}
              text="Save"
            />
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      {showDeleteTagDialog && (
        <ConfirmationDialog
          open
          message={t('pages.tags.confirmDelete')}
          handleYes={() => handleDeleteTag()}
          handleNo={() => setShowDeleteTagDialog(false)}
        />
      )}
    </>
  );
}
