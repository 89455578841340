import { PaginationParams } from 'types/common';
import { FigmaBackground, FigmaBackgroundsResponse } from 'types/other';

import { api } from './setupApi';

class FigmaBackgroundsModel {
  link = '/figma_backgrounds';

  getBackgrounds = (params: PaginationParams): Promise<FigmaBackgroundsResponse> =>
    api.get<FigmaBackgroundsResponse>(this.link, { params }).then(({ data }) => data);

  deleteBackground = (id: number) => api.delete(`${this.link}/${id}`);
}

export const figmaBackgroundsModel = new FigmaBackgroundsModel();
