import React, { useState, useEffect } from 'react';

import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core';

import { useShoppingProviderStatus } from 'contexts/ShoppingProviderContext';
import { getTWithPathKey } from 'utils';
import { ShoppingProviderStatus } from 'types';
import { useAuthState } from 'contexts/AuthContext';

import { BoxContainer, CloseIcon, CloseWrapBox } from 'components/UploadStatusBar/style';

const SECOND = 1000;
const CLOSE_DELAY = 10 * SECOND;

export default function DisconnectStatusBar() {
  const { result, status, isBlocked } = useShoppingProviderStatus();
  const {
    currentUser: { attributes: { shoppingPlatformName } },
  } = useAuthState();

  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (closed || result === null) return () => {};
    const timeout = setTimeout(() => setClosed(true), CLOSE_DELAY);

    return () => clearTimeout(timeout);
  }, [closed, result]);

  if (isBlocked) return <></>;

  if (result === null && status === ShoppingProviderStatus.disconnectInProgress) {
    if (closed) setClosed(false);

    const t = getTWithPathKey('dialogs.disconnectInProgress');

    return (
      <BoxContainer>
        <Box>
          <Typography variant="h4" display="inline">
            {t(`statusBar`, { storeName: shoppingPlatformName })}
          </Typography>
        </Box>
        <Box mt={3}>
          <LinearProgress color="primary" variant="indeterminate" />
        </Box>
      </BoxContainer>
    );
  }

  if (closed || result === null || status === null) return <></>;

  const t = getTWithPathKey('dialogs.disconnectResult');
  const close = () => setClosed(true);

  return (
    <BoxContainer>
      <Box>
        <Typography variant="h4" display="inline">
          {t(result ? `titleSuccess` : 'titleFailed', { storeName: shoppingPlatformName })}
        </Typography>
      </Box>
      <CloseWrapBox>
        <IconButton onClick={close} aria-label="delete">
          <CloseIcon />
        </IconButton>
      </CloseWrapBox>
    </BoxContainer>
  );
}
