import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { ShoppingProviderTypes } from 'types/enums';

import ShopifyProvider from './ShopifyProvider/ShopifyProvider';
import IdealProvider from './IdealProvider/IdealProvider';

const EcommerceProviderSettings = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const renderProviderComponent = () => {
    if (id === ShoppingProviderTypes.SHOPIFY) {
      return <ShopifyProvider providerName={id} />
    }

    if (id === ShoppingProviderTypes.IDEAL) {
      return <IdealProvider providerName={id} />
    }

    return null;
  };

  return (
    <>
      {renderProviderComponent()}
    </>
  )
}

export default EcommerceProviderSettings;
