import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import { OverlayCloseButtonSettings, OverlayColorsComponent } from 'components';
import { OverlayBackdropSettings } from 'components/OverlayBackdropSettings/OverlayBackdropSettings';
import { OverlayPreview } from 'components/OverlayPreviewSettings/OverlayPreview';

interface Settings {
  primaryColor: string;
  secondaryColor: string;
  iframePrimaryColor: string;
  iframeSecondaryColor: string;
  backgroundColor: string;
  opacity: number;
  blur: number;
}

interface OverlayPreviewSettingsProps {
  settings: Settings;
  onChange: (settings: Settings) => void;
}

export const OverlayPreviewSettings = ({ settings, onChange }: OverlayPreviewSettingsProps) => {
  const { primaryColor, secondaryColor, iframePrimaryColor, iframeSecondaryColor, backgroundColor, opacity, blur } =
    settings;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
      <OverlayColorsComponent
        settings={{ primaryColor, secondaryColor }}
        onChange={updatedSettings =>
          onChange({
            ...settings,
            primaryColor: updatedSettings.primaryColor,
            secondaryColor: updatedSettings.secondaryColor,
          })
        }
      />
      <Box my={4}>
        <Divider />
      </Box>
      <OverlayBackdropSettings
        settings={{
          backgroundColor,
          opacity,
          blur,
        }}
        onChange={updatedSettings =>
          onChange({
            ...settings,
            backgroundColor: updatedSettings.backgroundColor,
            opacity: updatedSettings.opacity,
            blur: updatedSettings.blur,
          })
        }
      />
      <Box my={4}>
        <Divider />
      </Box>
      <OverlayCloseButtonSettings
        settings={{ primaryColor: iframePrimaryColor, secondaryColor: iframeSecondaryColor }}
        onChange={updatedSettings =>
          onChange({
            ...settings,
            iframePrimaryColor: updatedSettings.primaryColor,
            iframeSecondaryColor: updatedSettings.secondaryColor,
          })
        }
      />
      <Box mb={4} mt={4}>
        <Typography variant="h4" display="inline">
          Preview
        </Typography>
      </Box>
      <OverlayPreview
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        closeButtonPrimaryColor={iframePrimaryColor}
        closeButtonSecondaryColor={iframeSecondaryColor}
        backgroundColor={backgroundColor}
        opacity={opacity}
        blur={blur}
      />
    </Box>
  );
};
