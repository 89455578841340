import { Box as MuiBox, styled } from '@material-ui/core';

export const Box = styled(MuiBox)({
  position: 'absolute',
  top: '48px',
  left: '50%',
  padding: '12px 24px 12px 12px',
  border: 'solid 1px #f2d2a2',
  borderRadius: '6px',
  background: '#fff4e5',
  color: '#c27501',
  width: '60%',
  transform: 'translateX(-50%)',
  zIndex: 1000,

  '& svg': {
    marginRight: '8px',
  },
});
