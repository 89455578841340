import { last } from 'lodash';

import { STATUSES_MAP } from 'components/DropzoneImage/constants';
import { EXTENSIONS_NAMES } from 'components/ImageUploader/constants';
import { ProductPicture } from 'types';
import { ImageSizes } from 'types/enums';
import { Image } from 'types/images';

import { getBlobUrl } from './getBlobUrl';

export const isSvg = (imageName: string): boolean => last(imageName?.split('.')) === EXTENSIONS_NAMES.svg;

const getThumbnail = (image: Image | ProductPicture, size: ImageSizes) =>
  image.thumbnails?.[`variant${size}Webp`] ??
  image.thumbnails?.variantSquarePng128 ??
  image.thumbnails?.variantThumbnailJpeg;

export const getImageThumbUrl = (
  image: Image | ProductPicture,
  thumbsOnly: boolean = false,
  imageSize = ImageSizes.thumbnail,
) => {
  if (!image) return undefined;

  let thumbPath = getThumbnail(image, imageSize);

  if (thumbsOnly && !thumbPath && isSvg(image.imageName)) {
    const svgPath = image.path ? getBlobUrl(image.path) : image.url;

    return svgPath || undefined;
  }

  if (!thumbsOnly && !thumbPath && image.path) {
    thumbPath = image.path;
  }

  if (thumbPath) {
    thumbPath = getBlobUrl(thumbPath);
  }

  return thumbsOnly ? thumbPath : thumbPath ?? image.url;
};

export const addImagesThumbUrl = (images: Image[]) =>
  images.map(image => ({
    ...image,
    thumbUrl: getImageThumbUrl(image),
  }));

export const getImagePreviewByStatus = (image: Image, thumbsOnly: boolean = false) =>
  STATUSES_MAP[image.status!] || getImageThumbUrl(image, thumbsOnly) || STATUSES_MAP.noImage;
