import { Button as MuiButton, Box as MuiBox, Typography as MuiTypography, withStyles } from '@material-ui/core';
import MuiAddIcon from '@material-ui/icons/Add';

import { COLORS, ICON_SIZE } from 'theme/constants';
import { TagEditIcon } from 'components/CustomIcons';

export const Box = withStyles({
  root: {
    '&.tags-wrapper': { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' },
    '&.tags-item': { marginTop: '10px', width: '47%' },
  },
})(MuiBox);

export const Button = withStyles(theme => ({
  root: {
    minHeight: '36px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    backgroundColor: COLORS.background,
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.08)',
    padding: '8px 10px',
    lineHeight: 0,

    '&.without-shadow': { boxShadow: 'none', backgroundColor: 'transparent' },
    '&.focus': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: COLORS.cozmosTagsHoverDefault,
    },
    '&:hover svg': {
      opacity: '1',
    },
    '&.active': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover.active': {
      backgroundColor: COLORS.cozmosTagsHoverActive,
    },
    '&.flex': { display: 'flex' },
    '&.ml-auto': { marginLeft: 'auto' },
    '&.active .count': { color: theme.palette.primary.contrastText },
    '&.untagged': { border: '1px solid transparent', color: 'initial', backgroundColor: COLORS.white },
    '&.untagged.active': { border: '1px solid #d6d6d8' },
    '&.untagged:hover, &.untagged:hover.active': { backgroundColor: '#f3f3f3' },
    '&.untagged .count': { color: COLORS.cozmosGreyText },
  },
}))(MuiButton);

export const Typography = withStyles({
  root: {
    '&.count': {
      fontSize: '12px',
      color: COLORS.cozmosGreyText,
      lineHeight: 1.5,
      marginBottom: '6px',
    },
    '&.name': {
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1.43,
      width: `calc(100% - ${ICON_SIZE.standard})`,
    },
  },
})(MuiTypography);

export const AddIcon = withStyles({
  root: {
    height: ICON_SIZE.standard,
    width: ICON_SIZE.standard,
    marginRight: '6px',
  },
})(MuiAddIcon);

export const EditIcon = withStyles({
  root: {
    bottom: '8px',
    right: '10px',
    position: 'absolute',
    height: ICON_SIZE.standard,
    width: ICON_SIZE.standard,
    color: 'inherit',
    opacity: '0',
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
})(TagEditIcon);
