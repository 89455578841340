import React, { createContext, PropsWithChildren, useState } from 'react';

import { generateContextHook } from '../utils/helpers/contextHelpers';
import { ShoppingProviderItem } from '../types';
import { shoppingModel } from '../models';

import { useServerErrorActions } from './ServerErrorContext';

export interface EcommercePlatformsType {
  providers: Record<string, number>;
}

export interface EcommercePlatformsDispatchType {
  getProviders: () => Promise<void>;
}

const initialState: EcommercePlatformsType = {
  providers: {},
};

const EcommercePlatformsStateContext = createContext<EcommercePlatformsType>(initialState);
const EcommercePlatformsDispatchContext = createContext<EcommercePlatformsDispatchType>({
  getProviders: () => Promise.resolve(),
});

const EcommercePlatformsProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, setState] = useState<EcommercePlatformsType>(initialState);
  const { handleServerError } = useServerErrorActions();

  const dispatchValue: EcommercePlatformsDispatchType = {
    getProviders: () =>
      shoppingModel.getShoppingProviders()
      .then((providers: ShoppingProviderItem[]) => {
        const providerTitleToId =
          providers.reduce((acc, { title, id }) => ({ ...acc, [title.toLowerCase()]: id }), {})

        setState(prevState => ({
          ...prevState,
          providers: providerTitleToId
        }))
      }).catch(handleServerError),
  };

  return (
    <EcommercePlatformsStateContext.Provider value={state}>
      <EcommercePlatformsDispatchContext.Provider value={dispatchValue}>
        {children}
      </EcommercePlatformsDispatchContext.Provider>
    </EcommercePlatformsStateContext.Provider>
  );
};

const useEcommercePlatformsState = generateContextHook(EcommercePlatformsStateContext, 'EcommerceProviders');
const useEcommercePlatformsActions = generateContextHook(EcommercePlatformsDispatchContext, 'EcommerceProviders');

export { EcommercePlatformsProvider, useEcommercePlatformsState, useEcommercePlatformsActions };
