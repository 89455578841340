import { access } from 'fs';

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Trans } from 'react-i18next';

import { CardComponent, PageHeader, ImageUploader } from 'components';
import { LINKS } from 'components/constants';
import { IN_PROGRESS_STATUSES } from 'components/DropzoneImage/constants';
import { ACCEPT_EXTENSIONS, EXTENSIONS_NAMES } from 'components/ImageUploader/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useTimeoutUpdate, useDeleteSelectedAssets } from 'hooks';
import { backgroundImagesModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey, imageSizeValidator } from 'utils';
import { withPagination } from 'utils/enhancers';
import { PaginationProps, Image, FileWithSignedId } from 'types';
import { figmaBackgroundsModel } from 'models/FigmaBackgroundsModel';

import { Typography } from 'components/ImageUploader/style';

const BG_IMAGE_MAX_SIZE = 5368709120;

enum BackgroundTypes {
  IMAGE_BACKGROUND = 'background',
  FIGMA_BACKGROUND = 'figma-background',
}

function BackgroundImages({
  paginationItemsCount,
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
}: PaginationProps) {
  const [images, setImages] = useState<Image[]>([]);
  const [backgroundType, setBackgroundType] = useState<BackgroundTypes>(BackgroundTypes.IMAGE_BACKGROUND);
  const [shouldImagesBeUpdated, setShouldImagesBeUpdated] = useState(true);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleDeleteAllRequest } = useDeleteSelectedAssets();
  const getT = getTWithPathKey('pages.backgroundImages');

  useEffect(() => {
    setImages([]);
    getBackgrounds();
  }, [backgroundType, paginationParams]);

  const getBackgrounds = (): Promise<void> => {
    if (backgroundType === BackgroundTypes.FIGMA_BACKGROUND) return getFigmaBackgrounds();
    return getBackgroundImages();
  };

  const getBackgroundImages = withPageProgressHandler(() =>
    backgroundImagesModel
      .getImages(paginationParams)
      .then(({ backgroundImages, count }) => {
        setImages(backgroundImages);
        setPaginationItemsCount(count);
        setShouldImagesBeUpdated(
          backgroundImages.some(({ status }) => status !== null && IN_PROGRESS_STATUSES.includes(status)),
        );
      })
      .catch(handleServerError),
  );

  const getFigmaBackgrounds = withPageProgressHandler(() =>
    figmaBackgroundsModel
      .getBackgrounds(paginationParams)
      .then(({ figmaBackgrounds, figmaBackgroundsCount }) => {
        const images = figmaBackgrounds.map(({ name, id, thumbnails }) => ({
          id,
          imageName: name,
          thumbnails,
        })) as Image[];

        setImages(images);
        setPaginationItemsCount(figmaBackgroundsCount);
      })
      .catch(handleServerError),
  );

  const loadImage = (image: FileWithSignedId) => {
    const signedId = get(image, 'signed_id');

    return backgroundImagesModel.postImage({ signedId }).then(resetPaginationPage);
  };

  const unloadImage = (imageId: number) =>
    backgroundType === BackgroundTypes.IMAGE_BACKGROUND
      ? backgroundImagesModel.deleteImage(imageId).then(getBackgrounds).catch(handleServerError)
      : figmaBackgroundsModel.deleteBackground(imageId).then(getBackgrounds).catch(handleServerError);

  useTimeoutUpdate(shouldImagesBeUpdated, getBackgrounds, images);

  return (
    <>
      <PageHeader title={getT('header', { paginationItemsCount })} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title={getT('title')}>
          <Box>
            <Typography>
              <Trans
                i18nKey="pages.backgroundImages.imageUploaderText"
                components={[
                  <a href={LINKS.backgroundsKnowledgeBase} target="_blank" rel="noopener noreferrer">
                    {getT('imageUploaderText')}
                  </a>,
                ]}
              />
            </Typography>
          </Box>
          <Box mt={5}>
            <Button
              onClick={() => setBackgroundType(BackgroundTypes.IMAGE_BACKGROUND)}
              color={backgroundType === BackgroundTypes.IMAGE_BACKGROUND ? 'primary' : 'inherit'}
              variant="contained"
              size="small"
              style={{ marginRight: 5 }}
            >
              Uploaded
            </Button>
            <Button
              onClick={() => setBackgroundType(BackgroundTypes.FIGMA_BACKGROUND)}
              color={backgroundType === BackgroundTypes.FIGMA_BACKGROUND ? 'primary' : 'inherit'}
              variant="contained"
              size="small"
              style={{ marginRight: 5 }}
            >
              Figma Exported
            </Button>
          </Box>
          <ImageUploader
            images={images}
            loadImage={loadImage}
            unloadImage={unloadImage}
            handleServerError={handleServerError}
            handleDeleteAll={assets =>
              handleDeleteAllRequest(assets, backgroundImagesModel.deleteImage, getBackgrounds)
            }
            additionalValidators={[imageSizeValidator(BG_IMAGE_MAX_SIZE)]}
            accept={`${ACCEPT_EXTENSIONS.img}, .${EXTENSIONS_NAMES.pdf}`}
            maxFiles={1}
            section={DOWNLOAD_SECTIONS.background}
            withCaptcha
            dropzoneText={getT('dropzoneText')}
            withUploadButton={backgroundType === BackgroundTypes.IMAGE_BACKGROUND}
            isUploadEnabled={backgroundType === BackgroundTypes.IMAGE_BACKGROUND}
            readonly={backgroundType === BackgroundTypes.FIGMA_BACKGROUND}
          />
        </CardComponent>
      </Grid>
    </>
  );
}

export default withPagination(BackgroundImages);
