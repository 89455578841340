import React, { useEffect, useRef, useState } from 'react';

import { IconButton, LinearProgress, Typography } from '@material-ui/core';
import { includes } from 'lodash';
import classnames from 'classnames';

import { ReactComponent as AudioPlayIcon } from 'assets/icons/assets/audio-preview-play.svg';
import { ReactComponent as AudioPauseIcon } from 'assets/icons/assets/audio-preview-pause.svg';
import AudioFileIcon from 'assets/icons/assets/audio-file-icon.svg';
import { AudioAsset } from 'types/audio';
import { COLORS } from 'theme/constants';

import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';

import { Box, Checkbox } from '../DropzoneImage/style';

function formatDuration(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export interface DropzoneAudioProps {
  audio: AudioAsset;
  isPlaying: boolean;
  selectedAudios: number[];
  handleDelete: (id: number) => void;
  onTogglePlay: () => void;
  onChangeSelected: (id: number) => void;
}

export const DropzoneAudio = ({
  audio,
  isPlaying,
  handleDelete,
  onTogglePlay,
  selectedAudios,
  onChangeSelected
}: DropzoneAudioProps): JSX.Element => {
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef<HTMLAudioElement>(null);

  const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const progressBar = event.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const x = event.clientX - rect.left; // x position within the element
    const percentage = x / rect.width;
    const newTime = percentage * duration;

    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleLoadMetadata = () => audioElement && setDuration(audioElement.duration);
    const handleTimeUpdate = () =>
      audioElement && setProgress((audioElement.currentTime / audioElement.duration) * 100);

    if (audioElement) {
      audioElement.addEventListener('loadedmetadata', handleLoadMetadata);
      audioElement.addEventListener('ended', onTogglePlay);
      audioElement.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('loadedmetadata', handleLoadMetadata);
        audioElement.removeEventListener('ended', onTogglePlay);
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [onTogglePlay]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isPlaying]);

  const getChecked = () => includes(selectedAudios, audio.id);

  const onImageBlockClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();

  return (
    <Box className={classnames('preview-block file-preview', {
      selected: getChecked()
    })} onClick={onImageBlockClick}>
      <Box className="preview">
        <IconWithConfirmation
          buttonClassName="top right absolute on-hover-button"
          message="file"
          value={audio.id}
          handleConfirm={handleDelete}
        />
        <Checkbox className="top left absolute" color="primary" checked={getChecked()} onChange={() => onChangeSelected(audio.id)} />
        <Box className="image-block">
          <img
            draggable="false"
            data-dz-thumbnail
            style={{
              width: '50%',
              height: '50%',
              margin: 'auto',
            }}
            src={AudioFileIcon}
            alt="audio file icon"
          />
        </Box>
      </Box>
      <Box className="file-name">{audio.fileName}</Box>
      <audio ref={audioRef} src={audio.url} />
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => onTogglePlay()}>{isPlaying ? <AudioPauseIcon /> : <AudioPlayIcon />}</IconButton>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ flexGrow: 1, margin: '0 2px', borderRadius: '3px' }}
          onClick={handleProgressBarClick}
        />
        <Typography style={{ fontSize: '12px', color: COLORS.cozmosPurple2 }}>
          {formatDuration(duration - (audioRef.current?.currentTime ?? 0))}
        </Typography>
      </Box>
    </Box>
  );
};
