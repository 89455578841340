import { VideoAsset } from 'types/other';
import { PicturesGetParams } from 'types';

import { api } from './setupApi';

interface EditVideo {
  seoTitle: string;
  seoDesc: string;
}

interface NewVideo extends EditVideo {
  signedId: string;
  h1Tag: string;
}

interface VideoResponse {
  count: number;
  videos: VideoAsset[];
  untaggedCount: number;
}

class VideoModel {
  prefix = '/videos';

  getVideos = (params: PicturesGetParams) => api.get<VideoResponse>(`${this.prefix}`, { params }).then(r => r.data);

  createVideo = (data: NewVideo) => api.post(`${this.prefix}`, data).then(({ data: { video } }) => video);

  updateVideo = (id: number, data: EditVideo) => api.put(`${this.prefix}/${id}`, data);

  deleteVideo = (id: number) => api.delete(`${this.prefix}/${id}`);
}

export const videosModel = new VideoModel();
