import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Grid, Divider } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  CardComponent,
  PageHeader,
  PageHeaderNav,
  ButtonComponent,
  IconWithConfirmation,
  IFrameStylesComponent
} from 'components';
import { AutocompleteValidatorComponent, TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { LINKS } from 'components/constants';
import { ROUTES } from 'routes/constants';
import { SocialContentInterface } from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { socialIntegrationModel } from 'models';

import { SocialContentTypeKeys } from '../constants';

import { DisclaimerText, Subtext } from '../InstagramIntegration/style';

interface SocialMediaSettings {
  id: number;
  title: string;
  contentTypes: Record<string, string>[];
}

const initialState = {
  id: 1,
  title: '',
  contentTypes: []
} as SocialMediaSettings;

const defaultColors = {
  hideOverlayContainer: false,
  containerColor: '#ffffff',
  closeIconColor: '#ffffff',
  closeBgColor: '',
  containerTransparent: false,
  scrollContainerFill: '',
  scrollIndicatorFill: '#000000',
  content: [],
  showLoader: false,
  backgroundColor: '',
  opacity: 0,
  blur: 0,
} as SocialContentInterface;

const SocialMediaComponent = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const {handleServerError} = useServerErrorActions();
  const [socialMediaSettings, setSocialMediaSettings] = useState(defaultColors);
  const {withPageProgressHandler} = useBusyProgressActions();
  const [socialContent, setSocialContent] = useState(initialState);

  useEffect(() => {
    getData();
  }, []);

  const getData = withPageProgressHandler(() =>
    socialIntegrationModel.getSocialProvider(id)
      .then(data => {
        setSocialContent({
          title: data.title,
          id: data.id,
          contentTypes: data.contentTypes.map(item => ({
            id: item
          }))
        });
        setSocialMediaSettings({ ...data.clientSocialMedias[0] as SocialContentInterface });
      })
      .catch(handleServerError));

  const handleChange = (index: number) => (event: Record<string, string>) =>
    setSocialMediaSettings(prevState => ({
      ...prevState,
      content: prevState.content.map((item, i) => (
        index === i ?
          { ...item, ...event } :
          { ...item }
      ))
    }));

  const handleAdd = () => {
    setSocialMediaSettings(prevState => ({
      ...prevState,
      content: [...prevState.content, {
        [SocialContentTypeKeys.NAME]: '',
        [SocialContentTypeKeys.TYPE]: '',
        [SocialContentTypeKeys.ID]: ''
      }]
    }));
  };

  const handleDelete = (index: number) =>
    setSocialMediaSettings(prevState => ({
      ...prevState,
      content: prevState.content.filter((item, i) => i !== index)
    }))

  const handleSubmit = withPageProgressHandler(() =>
    socialIntegrationModel.updateSocialProvider(
      {socialMediaId: id, id: socialMediaSettings.id, clientSocialMedia: socialMediaSettings})
      .then(getData)
      .catch(handleServerError));

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <PageHeader title={socialContent?.title} backLink={ROUTES.socialIntegration}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      <CardComponent title={`Connect your ${socialContent?.title} content`} isLoading={false}>
        <Grid container spacing={6} direction="column">
          <Grid item>
            <Subtext>
              <Trans
                i18nKey="Use the fields below to connect and display social content in overlays on your Spheres. If the platform allows you can select multiple post or feed types. To add social content to your sphere, use the Social Content Action.<br /><br /> Need help? <a>Follow this guide</a> to learn more about how social content can be used with Cozmos."
                components={{ a: <a href={LINKS.instagramConnectionGuide} target="_blank" rel="noreferrer" /> }}
              />
            </Subtext>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
            {!!socialMediaSettings.content.length && (
              socialMediaSettings.content.map((item, index) =>
                <Grid item sm={12} key={`option-${index}`}>
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    <Box mr={2}>
                      <TextValidatorComponent
                        label="Title"
                        value={item[SocialContentTypeKeys.NAME]}
                        fieldName={SocialContentTypeKeys.NAME}
                        disableMinHeight
                        handleChange={handleChange(index)}
                        style={{ width: '9vw', minWidth: '160px' }}
                      />
                    </Box>
                    <Box mr={2}>
                      <AutocompleteValidatorComponent
                        value={item[SocialContentTypeKeys.TYPE]}
                        fieldName={SocialContentTypeKeys.TYPE}
                        label="Select content type"
                        handleChange={handleChange(index)}
                        disableClearable
                        disableMinHeight
                        selectList={socialContent?.contentTypes}
                        itemsNamePath="id"
                        validators={[VALIDATORS_MAP.required]}
                        style={{ width: '9vw', minWidth: '160px' }}
                      />
                    </Box>
                    <Box mr={2}>
                      <TextValidatorComponent
                        validators={[VALIDATORS_MAP.required]}
                        label={item[SocialContentTypeKeys.TYPE] ?
                          `Enter ${item[SocialContentTypeKeys.TYPE]?.toLowerCase()} ID/Embed code` :
                          ''
                        }
                        disabled={!item[SocialContentTypeKeys.TYPE]}
                        value={item[SocialContentTypeKeys.ID]}
                        fieldName={SocialContentTypeKeys.ID}
                        disableMinHeight
                        handleChange={handleChange(index)}
                        style={{ width: '18vw', minWidth: '260px' }}
                      />
                    </Box>
                    <Box mb={-0.5}>
                      <IconWithConfirmation
                        value={index}
                        message="data"
                        handleConfirm={handleDelete}
                      />
                    </Box>
                  </Box>
                </Grid>
              )
            )}
          <Grid item>
            <ButtonComponent className="filled" color="secondary" text="Add Content" onClick={handleAdd} />
          </Grid>
          <Grid item>
            <DisclaimerText>
              Please note, we do not validate any links you add to these settings.
              Please test on a Generated sphere link before Publishing your sphere.
            </DisclaimerText>
          </Grid>
        </Grid>
      </CardComponent>
      <IFrameStylesComponent
        socialMediaSettings={socialMediaSettings}
        setSocialMediaSettings={setSocialMediaSettings}
      />
    </ValidatorForm>
  )
}

export default SocialMediaComponent;
