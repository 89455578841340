import React from 'react';

import { Grid, LinearProgress, Box, CircularProgress } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { getTWithPathKey } from 'utils';
import { useShoppingProviderBlocker } from 'contexts/ShoppingProviderContext';
import { useAuthState } from 'contexts/AuthContext';

import { DialogTitle, DialogContent } from 'components/Dialogs/style';

export default function DisconnectInProgressBlocker() {
  const getT = getTWithPathKey('dialogs.disconnectInProgress');
  const {
    currentUser: { attributes: { shoppingPlatformName } },
  } = useAuthState();

  const knownStatus = useShoppingProviderBlocker();

  const body = knownStatus ? (
    <>
      <DialogTitle>{getT('title')}</DialogTitle>
      <DialogContent>
        <Trans components={{ br: <br /> }} i18nKey="dialogs.disconnectInProgress.content" values={{storeName: shoppingPlatformName}} />
      </DialogContent>
      <Box my={4}>
        <LinearProgress color="primary" />
      </Box>
    </>
  ) : (
    <CircularProgress color="primary" />
  );

  return (
    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
      <Box>{body}</Box>
    </Grid>
  );
}
