import { PaginationParams } from './common';
import { ActionType, NavigationArrowType, OrderByDirection, OrderByParameter } from './enums';
import { Image, UploadedPicture } from './images';

export interface CategoryParams extends Record<string, any> {
  name: string;
  parentId?: number | null;
}

export interface CategoryItem {
  id: number;
  categories: CategoryItem[];
  level: number;
  name: string;
  nameWithPrefix: string;
  parentId: number;
  productCount: number;
  subcategoriesCount: number;
  updatedAt: string;
}

export interface CategoriesGetParams extends PaginationParams {
  name?: string;
  withChildren?: boolean;
}

export interface PlanogramGroupGetParams extends TableParams {
  name?: string;
}

export interface SubcategoryChildren {
  children: SubcategoryChildren[];
  subcategory: CategoryItem;
}

export interface CategoryItemResponse {
  category: CategoryItem;
  children: SubcategoryChildren[];
}

export interface CategoriesListResponse {
  categories: CategoryItem[];
  categoriesCount: number;
}

export interface CategoryForSelect {
  id: number;
  level: number;
  name: string;
  nameWithPrefix: string;
}

export interface Country {
  id: number;
  code: string;
  name: string;
}

export interface Currency {
  id: number;
  name: string;
}

export interface CountryCurrencyRelation {
  countryId: number;
  currencyId: number;
}

export interface CurrencyListItem extends Currency {
  countryIds: number[];
}

export interface ErrorResponse {
  id: number | null;
  clientSubdomainId: number;
  createdAt: string | null;
  planogramId: number | null;
  updatedAt: string | null;
}

export interface Subdomain {
  id: number;
  clientId: number;
  isDefault: boolean;
  subdomain: string;
}

export interface ClientSubdomainList {
  id: number;
  value: string;
}

export interface RedirectPlanogram {
  id: number;
  url: string;
}

export interface ErrorResponseData {
  errorResponse: ErrorResponse;
  planogramsToRedirect: RedirectPlanogram[];
  subdomain: Subdomain;
}

export interface ErrorResponseParams {
  planogramId: number;
  subdomainId: number;
}

export interface HostErrorResponse {
  id: number;
  host: string;
  planogramId: number;
}

export interface HostErrorResponsePlanogramData {
  externalHostId: number | null;
  id: number;
  urlPath: string;
}

export interface HostErrorResponseData {
  hostErrorResponses: HostErrorResponse[];
  planograms: HostErrorResponsePlanogramData[];
}

export interface HostErrorResponsePostParams {
  hostErrorResponses: HostErrorResponse[];
}

export interface SerializedClient {
  id: number;
  clientId: number;
  hasDraftPlanograms: boolean;
  hasGeneratedPlanograms: boolean;
  hasPublishedPlanograms: boolean;
  isDefault: boolean;
  subdomain: string;
}

export interface SerializedClientListResponse {
  serializedClients: SerializedClient[];
  serializedClientsCount: number;
}

export interface ClientSubdomainCreateParams {
  subdomain: string;
  isDefault: boolean;
}

export interface GoogleTagManagerForm {
  gtmId: string;
}

export interface GoogleTagManagerParams {
  analyticsProvider: GoogleTagManagerForm | Partial<MatomoAnalyticsForm>;
}

export interface MatomoAnalyticsForm {
  matomoUrl: string | null;
  matomoSiteIds: Record<string, string>[];
  navigationTypeB: string | null;
  navigationValueB: string | null;
  planogramIdA: number | null;
  testName: string | null;
  variantName: string | null;
}

export interface TableData {
  headers: (TableColumnHeader | string)[];
  rows: TableRow[];
}

export interface TableRow {
  cells: React.ReactNode[];
  actions?: TableRowAction[];
  onClick?: () => void;
  children?: TableRow[];
}

export interface TableColumnHeader {
  title: string;
  sortKey?: OrderByParameter;
}

export interface SortParams {
  orderBy?: OrderByParameter;
  orderDir?: OrderByDirection;
}

export interface TableRowAction {
  type: ActionType;
  onClick?: () => void;
  testId?: string;
}

export interface TableParams extends PaginationParams, SortParams {
  filterId?: number;
  search?: string;
}

export interface FilterItem {
  id: number;
  value: string;
}

export interface LocalizationIcon {
  name: string;
  path: string;
}

export interface SelectListItem extends Record<string, any> {
  id: number;
  code: string;
  name: string;
  icons?: LocalizationIcon[];
  readOnly?: boolean;
}

export interface IframeContent extends Image {
  id: number;
  h1Tag: string;
  seoDesc: string;
  seoTitle: string;
  fileName: string;
  contentType: string;
  body: IframeBodyContent;
}

export interface ProductItem extends SphereObject {
  data: ProductItemData;
  parentId: string;
}

export interface ProductItemData {
  product: {
    identifier: string;
    name: string;
    pictures_count: number;
  };
}

export interface ImageItem extends SphereObject {
  data: ImageItemData;
}

export interface ImageItemData {
  id: number;
  imageName: string;
  name: string;
}

export interface IframeBodyContent extends IframeContent {
  iframeLink: string;
  seoDesc: string;
  seoTitle: string;
  transparent: boolean;
  url: string;
}

export interface IframeLink extends SphereObject {
  h1Tag: string;
  seoDesc: string;
  seoTitle: string;
  link: string;
  body: IframeLinkBodyContent;
}

export interface IframeLinkBodyContent {
  url: string;
}

export interface VideoAsset extends Image {
  id: number;
  h1Tag: string;
  seoDesc: string;
  seoTitle: string;
  fileName: string;
  contentType: string;
}

export interface AnimationPathItem {
  delay: number;
  duration: number;
  itemId: string;
  name: string;
  panBeforeZoom: boolean;
  planogramName: string;
  transitionType: string;
  language: string;
}

export enum AnimationPathType {
  CUSTOM = 'custom',
  NAVIGATION_ARROWS = 'navigation_arrow',
  JOURNEY = 'journey',
}

export interface AnimationPath {
  id: string;
  uuid: string;
  applyFinalItemsAction?: boolean;
  autoplay?: boolean;
  startWithoutNavigating?: boolean;
  items: AnimationPathItem[];
  name: string;
  loop?: boolean;
  type: AnimationPathType;
}

export interface AnimationPathResponse {
  animationPaths: AnimationPath[];
  count: number;
}

export interface SphereObject {
  id: string;
  name: string;
  uuid: string;
}

export interface NavigationArrowAsset {
  navigationArrowId: number;
  otherAssetId: number;
  direction: NavigationArrowType;
  otherAsset: UploadedPicture;
}

export interface NavigationArrow {
  id: number;
  enabled: boolean;
  backgroundColor: string;
  disableGlowEffect: boolean;
  distributeArrows: boolean;
  touchDeviceOnly: boolean;
  animationPathId: string;
  navigationArrowOtherAssets: NavigationArrowAsset[];
}

export interface FigmaBackgroundAsset {
  uuid: string;
  x: number;
  y: number;
  width: number;
  height: number;
  imageId: number;
}

export interface FigmaBackgroundsResponse {
  figmaBackgrounds: FigmaBackground[];
  figmaBackgroundsCount: number;
}

export interface FigmaBackground {
  id: number;
  uuid: string;
  name: string;
  thumbnails: Record<string, string>;
  backgroundColor: string;
  items: FigmaBackgroundAsset[];
}
