import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function Ideal360Icon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 85 46">
      <svg width="85" height="46" viewBox="0 0 85 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.4093 25.5125H49.5367V27.646H40.7554V18.5395H43.4093V25.5125Z" fill="#1D5099"/>
        <path d="M36.9246 27.646L36.066 25.9027H31.0704L30.2118 27.646H27.4278L32.1372 18.5395H35.0382L39.7476 27.646H36.9246ZM31.9811 24.0164H35.1423L33.5682 20.7901L31.9811 24.0164Z" fill="#1D5099"/>
        <path d="M19.8418 25.6165H26.9449V27.646H17.227V18.5395H26.8018V20.569H19.8418V22.065H25.5789V24.0034H19.8418V25.6165Z" fill="#1D5099"/>
        <path d="M9.88872 18.5395C13.3882 18.5395 15.6128 20.2307 15.6128 23.0927C15.6128 25.9548 13.3882 27.646 9.88872 27.646H4.80211V18.5395H9.88872ZM9.68057 25.5385C11.632 25.5385 12.9069 24.7059 12.9069 23.0927C12.9069 21.4796 11.632 20.647 9.68057 20.647H7.45599V25.5385H9.68057Z" fill="#1D5099"/>
        <path d="M2.65388 27.646H0V18.5395H2.65388V27.646Z" fill="#1D5099"/>
        <path d="M78.3814 27.9451C74.8949 27.9451 72.7094 26.1629 72.7094 23.0927C72.7094 20.0225 74.8949 18.2402 78.3814 18.2402C81.8809 18.2402 84.0664 20.0225 84.0664 23.0927C84.0664 26.1629 81.8809 27.9451 78.3814 27.9451ZM78.3944 25.7856C80.2547 25.7856 81.3735 24.8359 81.3735 23.0927C81.3735 21.3494 80.2547 20.3998 78.3944 20.3998C76.5211 20.3998 75.4023 21.3494 75.4023 23.0927C75.4023 24.8359 76.5211 25.7856 78.3944 25.7856Z" fill="#D22D45"/>
        <path d="M67.3055 21.6226C69.2178 21.6226 71.6635 22.4032 71.6635 24.6408C71.6635 26.5401 69.8683 27.9451 66.642 27.9451C63.1555 27.9451 60.996 26.0848 60.996 23.0927C60.996 20.1006 63.1555 18.2402 66.642 18.2402C68.7755 18.2402 70.3756 18.8387 71.4164 19.7753L69.9854 21.2193C69.1658 20.5038 68.073 20.1526 66.9282 20.1526C65.1719 20.1526 63.832 20.764 63.6889 22.7154C64.6256 21.9739 66.0175 21.6226 67.3055 21.6226ZM66.642 26.0328C68.047 26.0328 68.9967 25.6035 68.9967 24.6668C68.9967 23.7171 68.047 23.3789 66.7461 23.3789C65.7443 23.3789 64.6256 23.6911 63.819 24.2765C64.1962 25.5905 65.276 26.0328 66.642 26.0328Z" fill="#D22D45"/>
        <path d="M51.6568 24.6409C52.3723 25.4735 53.439 25.9548 54.805 25.9548C55.9238 25.9548 57.0946 25.6816 57.0946 24.836C57.0946 23.9774 55.9888 23.8343 54.7139 23.8343H53.2699V22.065H54.7139C55.7937 22.065 56.8344 21.8439 56.8344 21.1544C56.8344 20.4389 55.7417 20.2307 54.753 20.2307C53.4 20.2307 52.3983 20.7251 51.7218 21.5187L50.0046 20.2958C50.9803 19.0599 52.7105 18.2663 54.844 18.2663C57.2637 18.2663 59.5404 19.0469 59.5404 20.8162C59.5404 21.8829 58.6817 22.7675 57.3938 22.9237C58.6817 23.1318 59.8916 23.7693 59.8916 25.1092C59.8916 26.9305 57.693 27.9192 54.9091 27.9192C52.6975 27.9192 50.8892 27.0996 49.8485 25.8247L51.6568 24.6409Z" fill="#D22D45"/>
      </svg>
    </SvgIcon>
  );
}
