import React, { ChangeEvent } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import { SocialContentInterface } from 'types';
import { DisclaimerText } from 'pages/settings/SocialIntegration/InstagramIntegration/style';
import { getTWithPathKey } from 'utils';

import { ColorPicker } from '../ColorPicker/ColorPicker';
import { CardComponent } from '../CardComponent/CardComponent';
import { OverlayCloseButtonSettings } from '../OverlayCloseButtonSettings/OverlayCloseButtonSettings';
import { IFrameScrollbarComponent } from '../IFrameScrollbarComponent/IFrameScrollbarComponent';
import { OverlayBackdropSettings } from '../OverlayBackdropSettings/OverlayBackdropSettings';

import { IFrameStylesPreview } from './IFrameStylesPreview';

import { Switch } from '../style';
import { InfoOutlinedIcon } from '../InfoTooltip/style';

interface IFrameStylesComponentInterface {
  socialMediaSettings: SocialContentInterface;
  setSocialMediaSettings: Function;
}

export const IFrameStylesComponent = ({
  socialMediaSettings,
  setSocialMediaSettings,
}: IFrameStylesComponentInterface) => {
  const onSwitchChange =
    (propName: string) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
      setSocialMediaSettings((prevState: SocialContentInterface) => ({
        ...prevState,
        [propName]: checked,
      }));
  const getT = getTWithPathKey('components.iframeStylesComponent');

  const onColorChange =
    (name: string) =>
    ({ hex }: any) =>
      setSocialMediaSettings((prevColors: any) => ({ ...prevColors, [name]: hex }));

  return (
    <CardComponent>
      <Box mb={6}>
        <Typography variant="h2" style={{ textTransform: 'uppercase' }} gutterBottom>
          Overlay Settings
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h4" display="inline">
          {getT('title')}
        </Typography>
      </Box>
      <Box my={6} ml={-2}>
        <Typography variant="h4">
          <Switch
            checked={socialMediaSettings.showLoader ?? false}
            onChange={event => onSwitchChange('showLoader')(event)}
          />
          {getT('showLoaderTitle')}
        </Typography>
      </Box>
      <Box my={6} ml={-2}>
        <Typography variant="h4">
          <Switch
            checked={socialMediaSettings.hideOverlayContainer ?? false}
            onChange={event => onSwitchChange('hideOverlayContainer')(event)}
          />
          {getT('primaryToggleTitle')}
        </Typography>
      </Box>
      {!socialMediaSettings.hideOverlayContainer && (
        <Box mt={2}>
          <Box mb={2}>
            <Typography variant="caption">{getT('colorHelperText')}</Typography>
          </Box>
          <ColorPicker setColor={onColorChange('containerColor')} color={{ hex: socialMediaSettings.containerColor }} />
        </Box>
      )}
      <Box my={6}>
        <Divider />
      </Box>
      <OverlayBackdropSettings
        settings={{
          backgroundColor: socialMediaSettings.backgroundColor ?? '',
          opacity: socialMediaSettings.opacity ?? 0,
          blur: socialMediaSettings.blur ?? 0,
        }}
        onChange={updatedSettings =>
          setSocialMediaSettings({
            ...socialMediaSettings,
            backgroundColor: updatedSettings.backgroundColor,
            opacity: updatedSettings.opacity,
            blur: updatedSettings.blur,
          })
        }
      />
      <Box my={6}>
        <Divider />
      </Box>
      <OverlayCloseButtonSettings
        settings={{
          primaryColor: socialMediaSettings.closeIconColor,
          secondaryColor: socialMediaSettings.closeBgColor,
        }}
        onChange={updatedSettings => {
          setSocialMediaSettings({
            ...socialMediaSettings,
            closeIconColor: updatedSettings.primaryColor,
            closeBgColor: updatedSettings.secondaryColor,
          });
        }}
      />
      <Box my={6}>
        <Divider />
      </Box>
      <IFrameScrollbarComponent
        {...{
          onColorChange,
          primaryColorKey: 'scrollIndicatorFill',
          primaryColorValue: socialMediaSettings.scrollIndicatorFill,
          secondaryColorKey: 'scrollContainerFill',
          secondaryColorValue: socialMediaSettings.scrollContainerFill,
        }}
      />
      <Box mb={4} mt={4}>
        <Typography variant="h4" display="inline">
          Preview
        </Typography>
      </Box>
      <IFrameStylesPreview
        overlayColor={socialMediaSettings.containerColor}
        hideOverlay={socialMediaSettings.hideOverlayContainer}
        closeButtonPrimaryColor={socialMediaSettings.closeIconColor}
        closeButtonSecondaryColor={socialMediaSettings.closeBgColor}
        backgroundColor={socialMediaSettings.backgroundColor}
        opacity={socialMediaSettings.opacity}
        blur={socialMediaSettings.blur}
        scrollbarTrackColor={socialMediaSettings.scrollContainerFill}
        scrollbarHandleColor={socialMediaSettings.scrollIndicatorFill}
      />
    </CardComponent>
  );
};
