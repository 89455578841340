import React, { ChangeEvent } from 'react';

import { Box, Checkbox, Grid, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';
import { getTWithPathKey } from 'utils';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { Subtext } from './style';

interface IFrameScrollbarComponentProps {
  primaryColorKey: string;
  primaryColorValue: string;
  secondaryColorKey: string;
  secondaryColorValue: string;
  onColorChange: (props: any) => any;
}

export const IFrameScrollbarComponent = ({
  primaryColorKey,
  primaryColorValue,
  secondaryColorKey,
  secondaryColorValue,
  onColorChange,
}: IFrameScrollbarComponentProps) => {
  const getT = getTWithPathKey('components.iframeScrollbarComponent');

  const handleCheckedColorChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    onColorChange(secondaryColorKey)(checked ? { hex: '' } : { hex: COLORS.white });

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Box mb={4}>
          <Typography variant="h4" display="inline">
            Overlay content scroll bar
          </Typography>
        </Box>
        <Box my={6}>
          <Subtext>{getT('subtitle')}</Subtext>
        </Box>
        <Box mt={5}>
          <Box mb={2}>
            <Typography variant="caption">{getT('primaryColorHelperText')}</Typography>
          </Box>
          <ColorPicker setColor={onColorChange(primaryColorKey)} color={{ hex: primaryColorValue }} />
        </Box>
        <Box mt={5}>
          <Box mb={2}>
            <Typography variant="caption">{getT('secondaryColorHelpText')}</Typography>
          </Box>
          <ColorPicker setColor={onColorChange(secondaryColorKey)} color={{ hex: secondaryColorValue }} />
        </Box>
        <Box ml={7}>
          <Typography variant="caption">
            <Checkbox color="primary" checked={!secondaryColorValue} onChange={handleCheckedColorChange} />
            <span>{getT('checkboxHelpText')}</span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
