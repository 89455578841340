export enum CustomDomainVerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  FAILED = 'failed',
  PENDING = 'pending',
  CONNECTED = 'connected',
}

export enum OrderByParameter {
  NAME = 'name',
  PICTURES_COUNT = 'pictures_count',
  UPDATED_AT = 'updated_at',
  ADMIN_NAME = 'admin_name',
  IDENTIFIER = 'identifier',
  CATEGORY_NAME = 'category_name',
  CREATED_AT = 'created_at',
}

export enum OrderByDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ActionType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  SWITCH = 'switch',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  NONE = 'none',
}

export enum Role {
  SYSTEM_ADMIN = 'system_admin',
  CLIENT_OWNER = 'client_owner',
  CLIENT_ADMIN = 'client_admin',
  CLIENT_MODERATOR = 'client_moderator',
}

export enum ThemeMode {
  LIGHT = 'lightMode',
  DARK = 'darkMode',
}

export enum ImageSizes {
  thumbnail = 'Thumbnail',
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
}

export enum SocialMediaTypes {
  pictures = 'pictures',
  videos = 'videos',
}

export enum ControlButtonNavigationType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  BACK = 'back',
}

export enum NavigationArrowType {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum CloningRequestStatus {
  PENDING = 'pending',
  DENIED = 'denied',
  APPROVED = 'approved'
}

export enum TagListTypes {
  TAGS = 'tag',
  IMAGES = 'image',
  VIDEOS = 'videos'
}

export enum ShoppingProviderTypes {
  SHOPIFY = 'shopify',
  IDEAL = 'ideal360'
}
