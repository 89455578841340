import React from 'react';

import { Typography } from '@material-ui/core';

import { CloseIcon } from 'components/OverlayCloseButtonSettings/style';
import {
  BackgroundImageContainer,
  CloseButtonWrapper,
  OverlayPreviewContainer,
  PreviewBox,
  WrapperContainer,
  InvertColorsIcon,
  TitleBox,
} from 'components/OverlayPreviewSettings/style';

interface OverlayPreviewProps {
  primaryColor: string;
  secondaryColor: string;
  closeButtonPrimaryColor: string;
  closeButtonSecondaryColor: string;
  backgroundColor: string;
  opacity: number;
  blur: number;
}

export const OverlayPreview = ({
  primaryColor,
  secondaryColor,
  closeButtonPrimaryColor,
  closeButtonSecondaryColor,
  backgroundColor,
  opacity,
  blur,
}: OverlayPreviewProps) => (
  <WrapperContainer>
    <BackgroundImageContainer />
    <OverlayPreviewContainer backgroundcolor={backgroundColor} opacity={opacity} blur={blur}>
      <PreviewBox className="primary" style={{ backgroundColor: primaryColor }}>
        <CloseButtonWrapper>
          <CloseIcon
            size="56px"
            style={{
              borderColor: closeButtonPrimaryColor,
              backgroundColor: closeButtonSecondaryColor,
            }}
          >
            <span style={{ color: closeButtonPrimaryColor }} />
          </CloseIcon>
        </CloseButtonWrapper>
        <TitleBox>
          <InvertColorsIcon />
          <Typography variant="h2" style={{ alignSelf: 'center' }}>
            Primary Colour
          </Typography>
        </TitleBox>
        <PreviewBox className="secondary" style={{ backgroundColor: secondaryColor }}>
          <TitleBox>
            <InvertColorsIcon />
            <Typography variant="h2" style={{ alignSelf: 'center' }}>
              Secondary Colour
            </Typography>
          </TitleBox>
        </PreviewBox>
      </PreviewBox>
    </OverlayPreviewContainer>
  </WrapperContainer>
);
