import React from 'react';

import { Typography } from '@material-ui/core';

import { CloseIcon } from 'components/OverlayCloseButtonSettings/style';
import {
  BackgroundImageContainer,
  CloseButtonWrapper,
  OverlayPreviewContainer,
  PreviewBox,
  WrapperContainer,
  InvertColorsIcon,
  TitleBox,
  ScrollbarArea,
  ScrollbarTrack,
  ScrollbarHandle
} from 'components/OverlayPreviewSettings/style';

interface IFrameStylesPreviewProps {
  overlayColor: string;
  hideOverlay: boolean;
  closeButtonPrimaryColor: string;
  closeButtonSecondaryColor: string;
  backgroundColor: string;
  opacity: number;
  blur: number;
  scrollbarTrackColor: string;
  scrollbarHandleColor: string;
}

export const IFrameStylesPreview = ({
  overlayColor,
  hideOverlay,
  closeButtonPrimaryColor,
  closeButtonSecondaryColor,
  backgroundColor,
  opacity,
  blur,
  scrollbarTrackColor,
  scrollbarHandleColor,
}: IFrameStylesPreviewProps) => (
  <WrapperContainer>
    <BackgroundImageContainer />
    <OverlayPreviewContainer backgroundcolor={backgroundColor} opacity={opacity} blur={blur}>
      <PreviewBox className="primary" style={{ backgroundColor: hideOverlay ? 'transparent' : overlayColor }}>
        <CloseButtonWrapper>
          <CloseIcon
            size="56px"
            style={{
              borderColor: closeButtonPrimaryColor,
              backgroundColor: closeButtonSecondaryColor,
            }}
          >
            <span style={{ color: closeButtonPrimaryColor }} />
          </CloseIcon>
        </CloseButtonWrapper>
        <TitleBox>
          <InvertColorsIcon style={{ color: '#282336' }} />
          <Typography variant="h2" style={{ alignSelf: 'center', color: '#282336' }}>
            Overlay colour
          </Typography>
        </TitleBox>
        <ScrollbarArea>
          <ScrollbarTrack style={{ background: scrollbarTrackColor }}>
            <ScrollbarHandle style={{ background: scrollbarHandleColor }} />
          </ScrollbarTrack>
        </ScrollbarArea>
      </PreviewBox>
    </OverlayPreviewContainer>
  </WrapperContainer>
);
