import React from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

import AnimationSettings from 'pages/settings/AnimationSettings/AnimationSettings';
import EditClientAdmin from 'pages/admins/EditClientAdmin/EditClientAdmin';
import EditSystemAdmin from 'pages/admins/EditSystemAdmin/EditSystemAdmin';
import ManageSpace from 'pages/admins/ManageSpace/ManageSpace';
import ManageAdmins from 'pages/admins/ManageAdmins/ManageAdmins';
import ManageReservedClients from 'pages/admins/ManageReservedClients/ManageReservedClients';
import ManageSystemAdmins from 'pages/admins/ManageSystemAdmins/ManageSystemAdmins';
import ProfileAdmins from 'pages/admins/ProfileAdmins/ProfileAdmins';
import Assets from 'pages/assets/Assets/Assets';
import BackgroundImages from 'pages/assets/BackgroundImages/BackgroundImages';
import ControlButton from 'pages/assets/ControlButton/ControlButton';
import Favicon from 'pages/assets/Favicon/Favicon';
import Fonts from 'pages/assets/Fonts/Fonts';
import Logo from 'pages/assets/Logo/Logo';
import OtherAssetsImages from 'pages/assets/OtherAssetsImages/OtherAssetsImages';
import CreatePassword from 'pages/auth/CreatePassword/CreatePassword';
import GoogleSignUpPage from 'pages/auth/GoogleSignUpPage/GoogleSignUpPage';
import GoogleSignUpWithInvitePage from 'pages/auth/GoogleSignUpWithInvitePage/GoogleSignUpWithInvitePage';
import InviteSelectorPage from 'pages/auth/InviteSelectorPage/InviteSelectorPage';
import { LinkExpired } from 'pages/auth/LinkExpired/LinkExpired';
import Page404 from 'pages/auth/Page404/Page404';
import Page500 from 'pages/auth/Page500/Page500';
import { ProfileSetupErrorPage } from 'pages/auth/ProfileSetupErrorPage/ProfileSetupErrorPage';
import ResetPassword from 'pages/auth/ResetPassword/ResetPassword';
import ResetToken from 'pages/auth/ResetToken/ResetToken';
import SelectAccountPage from 'pages/auth/SelectAccountPage/SelectAccountPage';
import SetPassword from 'pages/auth/SetPassword/SetPassword';
import { AccountNotFound } from 'pages/auth/SignInPage/AccountNotFound';
import SignInPage from 'pages/auth/SignInPage/SignInPage';
import SignUpPage from 'pages/auth/SignUpPage/SignUpPage';
import SignUpWithInvitePage from 'pages/auth/SignUpWithInvitePage/SignUpWithInvitePage';
import SsoCompletePage from 'pages/auth/SsoCompletePage/SsoCompletePage';
import TwoFactorSetup from 'pages/auth/TwoFactor/TwoFactorSetup/TwoFactorSetup';
import ManageCategories from 'pages/categories/ManageCategories/ManageCategories';
import EditClient from 'pages/clients/EditClient/EditClient';
import ManageClients from 'pages/clients/ManageClients/ManageClients';
import Heatmap from 'pages/Heatmap/Heatmap';
import Home from 'pages/Home/Home';
import ManageImages from 'pages/images/ManageImages/ManageImages';
import ManagePlanograms from 'pages/planograms/ManagePlanograms/ManagePlanograms';
import ManagePlanogramSettings from 'pages/planograms/ManagePlanogramSettings/ManagePlanogramSettings';
import ShowPlanogram from 'pages/planograms/ShowPlanogram/ShowPlanogram';
import EditProduct from 'pages/products/EditProduct/EditProduct';
import ManageProducts from 'pages/products/ManageProducts/ManageProducts';
import NewProduct from 'pages/products/NewProduct/NewProduct';
import Settings from 'pages/settings/Settings';
import AnalyticsProviders from 'pages/settings/AnalyticsProviders/AnalyticsProviders';
import GoogleTagManager from 'pages/settings/AnalyticsProviders/GoogleTagManager/GoogleTagManager';
import CookiesSettings from 'pages/settings/CookiesSettings/CookiesSettings';
import CurrencySettings from 'pages/settings/CurrencySettings/CurrencySettings';
import DomainManagementSettings from 'pages/settings/DomainManagementSettings/DomainManagementSettings';
import EcommerceOverlays from 'pages/settings/EcommerceOverlays/EcommerceOverlays';
import EcommerceProviderSettings from 'pages/settings/EcommerceProviders/EcommerceProviderSettings';
import EcommerceProviders from 'pages/settings/EcommerceProviders/EcommerceProviders';
import ErrorResponseSettings from 'pages/settings/ErrorResponseSettings/ErrorResponseSettings';
import OverlaysSettings from 'pages/settings/OverlaysSettings';
import PurchasingFlowSettings from 'pages/settings/PurchasingFlowSettings/PurchasingFlowSettings';
import SsoProvider from 'pages/settings/SsoProviders/SsoProvider/SsoProvider';
import SsoProviders from 'pages/settings/SsoProviders/SsoProviders';
import CreateTestPlanogram from 'pages/system/CreateTestPlanogram';
import ManageTags from 'pages/tags/ManageTags/ManageTags';
import { Maintenance } from 'pages/admins/Maintenance/Maintenance';
import { saveHeadersFromQueryParams } from 'utils';
import { withDownloadsStatus } from 'utils/enhancers';
import BillingSettings from 'pages/settings/BillingSettings/BillingSettings';
import SocialIntegration from 'pages/settings/SocialIntegration/SocialIntegration';
import InstagramIntegration from 'pages/settings/SocialIntegration/InstagramIntegration/InstagramIntegration';
import SocialMediaComponent from 'pages/settings/SocialIntegration/SocialMediaComponent/SocialMediaComponent';
import { ManageSocialContent } from 'pages/SocialContent/ManageSocialContent';
import OverlayContents from 'pages/assets/OverlayContents/OverlayContents';
import Video from 'pages/assets/Video/Video';
import Matomo from 'pages/settings/AnalyticsProviders/Matomo/Matomo';
import AbTesting from 'pages/settings/AnalyticsProviders/AbTesting/AbTesting';
import Audio from 'pages/assets/Audio/Audio';
import ConnectFigma from 'pages/auth/ConnectFigma/ConnectFigma';
import { Role } from 'types/enums';
import CloneRequestPage from 'pages/auth/CloneRequestPage/CloneRequestPage';
import CloneRequestExpiredPage from 'pages/auth/CloneRequestPage/CloneRequestExpiredPage';

import { ROUTES } from './constants';
import { RouteComponent } from './RouteComponent';

saveHeadersFromQueryParams();

const Routes = () => (
  <Router>
    <Switch>
      <RouteComponent path={ROUTES.home} component={Home} />
      <RouteComponent path={ROUTES.heatmap} component={Heatmap} />
      <RouteComponent path={ROUTES.manageClients} component={ManageClients} />
      <RouteComponent path={ROUTES.editClient} component={EditClient} />
      <RouteComponent path={ROUTES.manageSystemAdmins} component={ManageSystemAdmins} />
      <RouteComponent path={ROUTES.manageSpace} component={() => <ManageSpace />} />
      <RouteComponent
        path={ROUTES.manageClientAdmins}
        component={() => <ManageAdmins adminRole={Role.CLIENT_ADMIN} />}
      />
      <RouteComponent
        path={ROUTES.manageClientModerators}
        component={() => <ManageAdmins adminRole={Role.CLIENT_MODERATOR} />}
      />
      <RouteComponent path={ROUTES.editSystemAdmin} component={EditSystemAdmin} />
      <RouteComponent path={ROUTES.editClientAdmin} component={EditClientAdmin} />
      <RouteComponent path={ROUTES.editClientModerator} component={EditClientAdmin} />
      <RouteComponent path={ROUTES.manageProducts} component={ManageProducts} />
      <RouteComponent path={ROUTES.newProduct} component={NewProduct} />
      <RouteComponent path={ROUTES.editProduct} component={EditProduct} />
      <RouteComponent path={ROUTES.manageSpheres} component={ManagePlanograms} />
      <RouteComponent path={ROUTES.showSphere} component={ShowPlanogram} />
      <RouteComponent path={ROUTES.manageSpheresSettings} component={ManagePlanogramSettings} />
      <RouteComponent path={ROUTES.manageCategories} component={ManageCategories} />
      <RouteComponent path={ROUTES.manageTags} component={ManageTags} />
      <RouteComponent path={ROUTES.settings} component={Settings} />
      <RouteComponent path={ROUTES.purchasingFlowSettings} component={PurchasingFlowSettings} />
      <RouteComponent path={ROUTES.currencySettings} component={CurrencySettings} />
      <RouteComponent path={ROUTES.cookiesSettings} component={CookiesSettings} />
      <RouteComponent path={ROUTES.overlaysSettings} component={OverlaysSettings} />
      <RouteComponent path={ROUTES.errorResponseSettings} component={ErrorResponseSettings} />
      <RouteComponent path={ROUTES.ecommerceProviders} component={EcommerceProviders} />
      <RouteComponent path={ROUTES.ecommerceOverlays} component={EcommerceOverlays} />
      <RouteComponent path={ROUTES.ecommerceProviderSettings} component={(props: any) =>
        <EcommerceProviderSettings {...props} />
      }/>
      <RouteComponent path={ROUTES.ssoProviders} component={SsoProviders} />
      <RouteComponent path={ROUTES.ssoProviderSettings} component={SsoProvider} />
      <RouteComponent path={ROUTES.profile} component={ProfileAdmins} />
      <RouteComponent path={ROUTES.manageImages} component={ManageImages} />
      <RouteComponent path={ROUTES.createPassword} component={CreatePassword} />
      <RouteComponent path={ROUTES.authSignIn} component={SignInPage} />
      <RouteComponent path={ROUTES.authOktaComplete} component={SsoCompletePage} />
      <RouteComponent path={ROUTES.authSignUp} component={SignUpPage} />
      <RouteComponent path={ROUTES.inviteSelector} component={InviteSelectorPage} />
      <RouteComponent path={ROUTES.signUpWithInvite} component={SignUpWithInvitePage} />
      <RouteComponent path={ROUTES.socialSignUpWithInvite} component={GoogleSignUpWithInvitePage} />
      <RouteComponent path={ROUTES.profileSetupError} component={ProfileSetupErrorPage} />
      <RouteComponent path={ROUTES.setPassword} component={SetPassword} />
      <RouteComponent path={ROUTES.linkExpired} component={LinkExpired} />
      <RouteComponent path={ROUTES.tFASettingsNew} component={TwoFactorSetup} />
      <RouteComponent path={ROUTES.resetPassword} component={ResetPassword} />
      <RouteComponent path={ROUTES.resetTFA} component={ResetToken} />
      <RouteComponent path={ROUTES.page404} component={Page404} />
      <RouteComponent path={ROUTES.page500} component={Page500} />
      <RouteComponent path={ROUTES.assets} component={Assets} />
      <RouteComponent path={ROUTES.logo} component={Logo} />
      <RouteComponent path={ROUTES.fontsAssets} component={Fonts} />
      <RouteComponent path={ROUTES.faviconAssets} component={Favicon} />
      <RouteComponent path={ROUTES.controlButtonAssets} component={ControlButton} />
      <RouteComponent path={ROUTES.backgroundImagesAssets} component={BackgroundImages} />
      <RouteComponent path={ROUTES.otherAssets} component={OtherAssetsImages} />
      <RouteComponent path={ROUTES.overlayContent} component={OverlayContents} />
      <RouteComponent path={ROUTES.videoAssets} component={Video} />
      <RouteComponent path={ROUTES.audioAssets} component={Audio} />
      <RouteComponent path={ROUTES.manageReservedClients} component={ManageReservedClients} />
      <RouteComponent path={ROUTES.createTestPlanogram} component={CreateTestPlanogram} />
      <RouteComponent path={ROUTES.authGoogleSignUp} component={GoogleSignUpPage} />
      <RouteComponent path={ROUTES.authGoogleAccountNotFound} component={AccountNotFound} />
      <RouteComponent path={ROUTES.chooseSpace} component={SelectAccountPage} />
      <RouteComponent path={ROUTES.analyticsProviders} component={AnalyticsProviders} />
      <RouteComponent path={ROUTES.googleTagManager} component={GoogleTagManager} />
      <RouteComponent path={ROUTES.matomoAnalytics} component={Matomo} />
      <RouteComponent path={ROUTES.domainManagementSettings} component={DomainManagementSettings} />
      <RouteComponent path={ROUTES.billingSettings} component={BillingSettings} />
      <RouteComponent path={ROUTES.maintenance} component={Maintenance} />
      <RouteComponent path={ROUTES.socialIntegration} component={SocialIntegration} />
      <RouteComponent path={ROUTES.animationSettings} component={AnimationSettings} />
      <RouteComponent
        path={ROUTES.socialIntegrationSettings}
        component={(props: any) => {
          const { id } = props.match.params;
          const paramId = parseInt(id, 10);
          const instagramId = 1;

          return paramId === instagramId ? <InstagramIntegration {...props} /> : <SocialMediaComponent {...props} />;
        }}
      />
      <RouteComponent path={ROUTES.socialContent} component={ManageSocialContent} />
      <RouteComponent path={ROUTES.connectFigma} component={ConnectFigma} />
      <RouteComponent path={ROUTES.AbTesting} component={AbTesting} />
      <RouteComponent path={ROUTES.cloningExpired} component={CloneRequestExpiredPage} />
      <RouteComponent path={ROUTES.confirmCloning} component={() => <CloneRequestPage isConfirmed />} />
      <RouteComponent path={ROUTES.declineCloning} component={() => <CloneRequestPage isConfirmed={false} />} />
      <RouteComponent component={Page404} exact={false} />
    </Switch>
  </Router>
);

export default withDownloadsStatus(Routes);
