import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { isEmpty, isEqual, pick } from 'lodash';

import { CardComponent, ColoredBox, LinkComponent, PageHeader, SettingsForm, ShoppingProviderHint } from 'components';
import { ShopifyDisconnectDialog } from 'components/Dialogs/ShopifyDisconnectDialog/ShopifyDisconnectDialog';
import { ResyncDataDialog } from 'components/Dialogs';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { authModel, shoppingModel } from 'models';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { ROUTES } from 'routes/constants';
import { ClientShoppingProvider, DataServerErrors, NotificationItem } from 'types';
import { getTWithPathKey } from 'utils';
import { useShoppingProviderStatus } from 'contexts/ShoppingProviderContext';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { useEcommercePlatformsActions, useEcommercePlatformsState } from 'contexts/EcommercePlatformsContext';

import ConfirmationRedirect from './ConfirmationRedirect';
import { defaultSettings } from './constants';
import { EcommerceGuide } from './EcommerceGuide';
import { ErrorsLogComponent } from './ErrorsLogComponent';
import { LocalizedContentData } from './LocalizedContentData';
import { MultiPassComponent } from './MultiPassComponent';
import { SyncStatusComponent } from './SyncStatusComponent';
import { EcommerceProviderBlocker } from './EcommerceProviderBlocker';

import { Backdrop } from 'components/CardComponent/style';

const ShopifyProvider = ({
 providerName
}: { providerName: string }) => {
  const { shouldBlock, refreshStatus } = useShoppingProviderStatus();

  const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false);
  const [openResyncDataDialog, setOpenResyncDataDialog] = useState(false);
  const [initialClientShoppingProvider, setInitialClientShoppingProvider] =
    useState<ClientShoppingProvider>(defaultSettings);
  const [clientShoppingProvider, setClientShoppingProvider] = useState<ClientShoppingProvider>(defaultSettings);
  const [isMultipassEnabled, setMultipassEnabled] = useState(false);
  const [isEcommerceGuideOpen, setIsEcommerceGuideOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(false);
  const [isSyncLoading, setSyncLoading] = useState(false);
  const [isMultipassLoading, setMultipassLoading] = useState(false);
  const [notificationMessages, setNotificationMessages] = useState<NotificationItem[]>([]);
  const { handleServerError, resetServerErrors } = useServerErrorActions();
  const {
    currentUser: { attributes },
  } = useAuthState();
  const { updateUserData } = useAuthActions();

  const getT = getTWithPathKey('pages.ecommerceProvider');
  const { providers } = useEcommercePlatformsState();
  const { getProviders } = useEcommercePlatformsActions();
  const providerId = providers[providerName];
  const useAnotherProvider = attributes.shoppingPlatformEnabled && attributes.shoppingPlatformId !== providerId;

  useEffect(() => {
    if (providers[providerName]) {
      getData();
    } else {
      getProviders();
    }
  }, [providers]);

  useEffect(() => {
    if (title) {
      fetchNotificationMsgs();
    }
  }, [title]);

  const fetchNotificationMsgs = () =>
    shoppingModel
      .getMessagesLog({ title })
      .then(setNotificationMessages)
      .catch(handleServerError);

  const getData = async (withLoading: boolean = true) => {
    if (withLoading) {
      setInitialLoading(true);
    }

    await shoppingModel
      .getShoppingProvider(providerId)
      .then(data => {
        const settings = isEmpty(data.clientShoppingProvider) ? defaultSettings : data.clientShoppingProvider;

        setTitle(data?.title);
        setInitialClientShoppingProvider(settings);
        setClientShoppingProvider(settings);
        setMultipassEnabled(!!settings.shopifyMultipassSecret);
      })
      .catch(handleServerError)
      .finally(() => {
        setInitialLoading(false);
      });
  };

  const getIsSaveButtonVisible = () => !isEqual(initialClientShoppingProvider, clientShoppingProvider);

  const getIsMutlipassSaveButtonVisible = () =>
    !!clientShoppingProvider.connected &&
    !isEqual(
      {
        shopifyMultipassSecret: initialClientShoppingProvider.shopifyMultipassSecret,
        shopifyMultipassUrl: initialClientShoppingProvider.shopifyMultipassUrl,
      },
      {
        shopifyMultipassSecret: clientShoppingProvider.shopifyMultipassSecret,
        shopifyMultipassUrl: clientShoppingProvider.shopifyMultipassUrl,
      },
    );

  const getRequest = () =>
    clientShoppingProvider.id
      ? shoppingModel.putShoppingProvider(
          providerId,
          pick(clientShoppingProvider, [
            'apiKey',
            'shopName',
            'password',
            'storefrontAccessToken',
            'sharedSecret',
            'shopifyMultipassSecret',
            'shopifyMultipassUrl',
            'id',
          ]),
        )
      : shoppingModel.postShoppingProvider(
          providerId,
          pick(clientShoppingProvider, [
            'apiKey',
            'shopName',
            'password',
            'storefrontAccessToken',
            'shopifyMultipassSecret',
            'shopifyMultipassUrl',
            'sharedSecret',
          ]),
        );

  const handleDisconnect = async (clientName: string) => {
    try {
      setDeleteLoading(true);

      await shoppingModel.disconnectStore(providerId, clientShoppingProvider.id!, clientName);
      await refreshStatus();
      setOpenDisconnectDialog(false);
      await getData(false);
      await updateUserInfo();
    } catch (e) {
      handleServerError(e as ServerErrorType);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleCloseDisconnect = () => {
    setOpenDisconnectDialog(false);
    resetServerErrors();
  };

  const handleSave = async () => {
    try {
      setSyncLoading(true);
      const initialRedirectParams = pick(initialClientShoppingProvider, ['redirectEnabled', 'redirectDelay']);
      const redirectParams = pick(clientShoppingProvider, ['redirectEnabled', 'redirectDelay']);

      if (!isEqual(initialRedirectParams, redirectParams)) {
        await shoppingModel.setRedirectDelay(providerId, clientShoppingProvider.id!, redirectParams);
      }
      const data = await getRequest();

      setInitialClientShoppingProvider(data.clientShoppingProvider);
      setClientShoppingProvider(data.clientShoppingProvider);

      if (attributes.allowAddProduct) {
        updateUserData({ ...attributes, allowAddProduct: false });
      }

      if (!data.clientShoppingProvider.connected) {
        await fetchNotificationMsgs();
      } else {
        await updateUserInfo();
      }
    } catch (e) {
      handleServerError(e as DataServerErrors<string[]>);
    } finally {
      setSyncLoading(false);
    }
  };

  const handleMultipassSettingsSave = async () => {
    try {
      setMultipassLoading(true);

      const data = await getRequest();

      setInitialClientShoppingProvider(data.clientShoppingProvider);
      setClientShoppingProvider(data.clientShoppingProvider);
      setMultipassEnabled(!!data.clientShoppingProvider.shopifyMultipassSecret);
    } catch (e) {
      handleServerError(e as DataServerErrors<string[]>);
    } finally {
      setMultipassLoading(false);
    }
  };

  const updateUserInfo = async () => authModel.verifyToken().then(updateUserData);

  const handleChange = (item: Partial<ClientShoppingProvider>) =>
    setClientShoppingProvider(prev => ({ ...prev, ...item }));

  const isLoading = isInitialLoading || isSyncLoading;

  const handleMultipassSwitch = () => {
    if (isMultipassEnabled) {
      handleChange({
        shopifyMultipassSecret: '',
        shopifyMultipassUrl: '',
      });
    } else {
      handleChange({
        shopifyMultipassSecret: initialClientShoppingProvider.shopifyMultipassSecret,
        shopifyMultipassUrl: initialClientShoppingProvider.shopifyMultipassUrl,
      });
    }

    setMultipassEnabled(prev => !prev);
  };

  const toggleGuide = () => setIsEcommerceGuideOpen(prev => !prev);

  const handleToggleDialog = () => setOpenResyncDataDialog(prev => !prev);

  const handleReSync = (options?: Array<string>) => {
    const parsedOptions = options?.reduce((obj, option) => ({ ...obj, [`resync_${option.toLowerCase()}`]: true }), {});

    return shoppingModel
      .startReSynchronization(providerId, clientShoppingProvider.id!, parsedOptions)
      .then(getData)
      .catch(handleServerError)
      .finally(handleToggleDialog);
  };

  return (
    <>
      {shouldBlock && <EcommerceProviderBlocker />}
      <PageHeader title={title} backLink={ROUTES.ecommerceProviders} />
      {isDeleteLoading && (
        <Backdrop open={isDeleteLoading}>
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <CircularProgress size={40} thickness={4} />
          </Box>
        </Backdrop>
      )}
      {!isInitialLoading && !clientShoppingProvider.connected && (
        <CardComponent wrapperClassName="hintBlock" contentClassName="hintContent" xs={9}>
          <Typography color="inherit">{getT('hintText')}</Typography>
        </CardComponent>
      )}
      <SettingsForm
        isSaveButtonVisible={getIsSaveButtonVisible()}
        handleSave={handleSave}
        confirmMessage={!clientShoppingProvider.connected ? getT('confirmation') : undefined}
      >
        {useAnotherProvider && providerId && (
          <ShoppingProviderHint
            connectedStore={attributes.shoppingPlatformName}
            currentStore={title}
          />
        )}
        <CardComponent
          isLoading={isLoading || useAnotherProvider}
          useSpinner={!useAnotherProvider}
          title={getT(title)}
          xs={9}
        >
          <Grid container>
            <Grid item sm={12}>
              <Box mt={5} mb={6}>
                <Typography className="information">
                  {getT('captionStart')}
                  <LinkComponent className="blue" onClick={toggleGuide}>
                    {getT('link')}
                  </LinkComponent>
                  {getT('captionEnd')}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required]}
                label={getT('shopName')}
                value={clientShoppingProvider.shopName}
                fieldName="shopName"
                handleChange={handleChange}
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            <Grid item sm={4}>
              <Box display="flex" alignItems="center" ml={5} mt={3}>
                {clientShoppingProvider.connected && (
                  <>
                    <StatusComponent statusText={getT('successConnect')} status={UPLOAD_STATUSES.SUCCESS} />
                    <StatusComponent
                      statusText="Disconnect"
                      status={UPLOAD_STATUSES.ERROR}
                      onClick={() => setOpenDisconnectDialog(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                id="api-key"
                autoComplete="new-password"
                validators={[VALIDATORS_MAP.required]}
                label={getT('apiKey')}
                value={clientShoppingProvider.apiKey}
                fieldName="apiKey"
                handleChange={handleChange}
                type="password"
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                id="api-key-psswrd"
                autoComplete="new-password"
                validators={[VALIDATORS_MAP.required]}
                label={getT('password')}
                value={clientShoppingProvider.password}
                fieldName="password"
                type="password"
                handleChange={handleChange}
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                id="shared-secret"
                autoComplete="new-password"
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.lettersNumbersUnderscores]}
                label={getT('sharedSecret')}
                value={clientShoppingProvider.sharedSecret}
                fieldName="sharedSecret"
                type="password"
                handleChange={handleChange}
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                id="storefront-access-token"
                autoComplete="new-password"
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
                label={getT('storefrontAccessToken')}
                value={clientShoppingProvider.storefrontAccessToken}
                fieldName="storefrontAccessToken"
                type="password"
                handleChange={handleChange}
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            {clientShoppingProvider.id && !clientShoppingProvider.connected && (
              <Grid item sm={8}>
                <Box mb={6}>
                  <ColoredBox variant="error">
                    <Box my={3} mx={2}>
                      <Typography>{getT('error')}</Typography>
                    </Box>
                  </ColoredBox>
                </Box>
              </Grid>
            )}
            {clientShoppingProvider.connected && (
              <>
                <LocalizedContentData />
                <ConfirmationRedirect
                  enabled={clientShoppingProvider.redirectEnabled ?? false}
                  delay={clientShoppingProvider.redirectDelay}
                  onChange={(enabled, delay) => handleChange({ redirectEnabled: enabled, redirectDelay: delay })}
                />
                <SyncStatusComponent
                  providerName={providerName}
                  providerId={providerId}
                  clientShoppingProvider={clientShoppingProvider}
                  getShoppingProvider={getData}
                  handleServerError={handleServerError}
                  handleToggleDialog={handleToggleDialog}
                />
              </>
            )}
          </Grid>
        </CardComponent>
      </SettingsForm>
      {clientShoppingProvider.connected && (
        <SettingsForm isSaveButtonVisible={getIsMutlipassSaveButtonVisible()} handleSave={handleMultipassSettingsSave}>
          <MultiPassComponent
            onChange={handleChange}
            isLoading={isMultipassLoading}
            isMultipassEnabled={isMultipassEnabled}
            setMultipassEnabled={handleMultipassSwitch}
            shopifyMultipassSecret={clientShoppingProvider.shopifyMultipassSecret}
            shopifyMultipassUrl={clientShoppingProvider.shopifyMultipassUrl}
          />
        </SettingsForm>
      )}
      <EcommerceGuide isOpen={isEcommerceGuideOpen} handleClose={toggleGuide} />
      {!isInitialLoading && <ErrorsLogComponent notificationMessages={notificationMessages} />}
      {openDisconnectDialog && clientShoppingProvider.id && (
        <ShopifyDisconnectDialog
          storeName={title}
          onClose={handleCloseDisconnect}
          onSubmit={handleDisconnect}
          loading={isDeleteLoading}
        />
      )}
      <ResyncDataDialog
        isOpen={openResyncDataDialog}
        handleToggleDialog={handleToggleDialog}
        handleReSync={handleReSync}
      />
    </>
  );
};

export default ShopifyProvider;
