import React, { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Divider, Grid, LinearProgress } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  ButtonComponent,
  CardComponent,
  ColoredBox,
  IFrameStylesComponent,
  PageHeader,
  PageHeaderNav
} from 'components';
import { LINKS } from 'components/constants';
import { ROUTES } from 'routes/constants';
import { ICON_SIZE } from 'theme/constants';
import { useTimeoutUpdate } from 'hooks';
import { getTWithPathKey } from 'utils';
import { NotificationItem, ShoppingProviderStatus, SocialContentInterface, SocialMedia } from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useAuthState } from 'contexts/AuthContext';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { NOTIFICATION_TYPES } from 'constants/technical';
import { shoppingModel, socialIntegrationModel } from 'models';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { getNotifications } from 'utils/getNotifications';
import profileIcon from 'assets/icons/settings/profile-icon.jpeg';

import { ErrorsLogComponent } from '../../EcommerceProviders/ShopifyProvider/ErrorsLogComponent';

import { Typography } from 'components/CardComponent/style';
import { DisclaimerText, ProfileName, Subtext, UserName } from './style';

const inProgressStatus = [ShoppingProviderStatus.inProgress, ShoppingProviderStatus.disconnectInProgress];

const initialState: SocialMedia = {
  connected: false,
  fullName: '',
  id: 1,
  loginWithInstagramUrl: '',
  profilePicUrl: '',
  syncStatus: ShoppingProviderStatus.idle,
  userName: '',
}

const InstagramIntegration = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation();
  const getT = getTWithPathKey('pages.settings.socialIntegration.instagram');
  const {handleServerError} = useServerErrorActions();
  const history = useHistory();
  const {withPageProgressHandler} = useBusyProgressActions();
  const [socialMedia, setSocialMedia] = useState<SocialMedia>(initialState);
  const [notificationMessages, setNotificationMessages] = useState<NotificationItem[]>([]);
  const {
    currentUser: {
      attributes: { clientName },
    },
  } = useAuthState();

  useEffect(() => {
    getData();
  }, []);

  const getData = withPageProgressHandler(() =>
    socialIntegrationModel.getSocialProvider(id)
      .then(data => {
        const params = new URL(window.location.href).searchParams;
        const code = params.get('code');

        if (code) {
          getToken(code)
            .finally(() => {
              const url = new URL(window.location.href);
              const params = new URLSearchParams(url.search);

              params.delete('code');

              history.replace({
                search: params.toString()
              });
            })
        }

        setSocialMedia({...data.clientSocialMedias[0] as SocialMedia});
      })
      .catch(handleServerError)
      .finally(fetchNotificationMessages)
  );

  const fetchNotificationMessages = () =>
    shoppingModel.getMessagesLog({ title: getT('title') }).then(setNotificationMessages).catch(handleServerError);

  const getToken = withPageProgressHandler((code: string) =>
    socialIntegrationModel.authCodeForToken(id, code)
  );

  const handleClick = () => {
    const { loginWithInstagramUrl } = socialMedia;

    window.location.href = loginWithInstagramUrl;
  };

  const handleSync = () => {
    socialIntegrationModel.startSynchronization(id)
      .then(getData)
      .catch(handleServerError);
  };

  const handleDisconnect = () => {
    socialIntegrationModel.startInstagramDisconnect(id, clientName)
      .then(getData)
      .catch(handleServerError);
  };

  const handleSubmit = withPageProgressHandler(() =>
    socialIntegrationModel.updateSocialProvider(
      {socialMediaId: id, id: socialMedia.id, clientSocialMedia: socialMedia as SocialContentInterface})
      .then(getData)
      .catch(handleServerError)
      .finally(fetchNotificationMessages));

  useTimeoutUpdate(
    inProgressStatus.includes(socialMedia.syncStatus),
    getData,
    socialMedia
  );

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit}>
        <PageHeader title={getT('title')} backLink={ROUTES.socialIntegration}>
          <PageHeaderNav withSubmit />
        </PageHeader>
        <CardComponent title={getT('cardTitle')} isLoading={false}>
          <Grid container spacing={6} direction="column">
            <Grid item>
              <Subtext>
                <Trans
                  i18nKey={getT('cardSubText')}
                  components={{ a: <a href={LINKS.instagramConnectionGuide} target="_blank" rel="noreferrer" /> }}
                />
              </Subtext>
            </Grid>
            {!socialMedia.connected && (
              <Grid item>
                <ButtonComponent className="filled" text="Sign in with Instagram" color="primary" onClick={handleClick} />
              </Grid>
            )}
            <Grid item>
              <DisclaimerText>{getT('disclaimer')}</DisclaimerText>
            </Grid>
            {socialMedia.connected && (
              <>
                <Box p={3}>
                  <Divider />
                </Box>
                <Grid container>
                  <Grid item sm={12}>
                    <Box mx={3}>
                      <Typography variant="h2">Active Feed</Typography>
                      <Box my={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" alignItems="center">
                            <img
                              width={ICON_SIZE.very_big}
                              crossOrigin="anonymous"
                              src={socialMedia.profilePicUrl || profileIcon}
                              alt=""
                              style={{ borderRadius: '50%' }}
                            />
                            <Box ml={3}>
                              <UserName>{socialMedia.userName}</UserName>
                              <ProfileName>{socialMedia.fullName ?? ''}</ProfileName>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <StatusComponent
                              statusText={t('pages.ecommerceProvider.successConnect')}
                              status={UPLOAD_STATUSES.SUCCESS}
                            />
                            <StatusComponent
                              statusText="Disconnect"
                              status={UPLOAD_STATUSES.ERROR}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={handleDisconnect}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box p={3}>
                  <Divider />
                </Box>
                <Grid item>
                  <Typography variant="h2">Sync your instagram feed</Typography>
                  {inProgressStatus.includes(socialMedia.syncStatus) ? (
                    <Box my={3}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <>
                      {socialMedia.connected && socialMedia.syncStatus === ShoppingProviderStatus.failed ? (
                        <Box my={3}>
                          <ColoredBox variant="sync-failed with-border">
                            <Box my={5} mx={2}>
                              <Typography variant="body2" className="inherit">{getT('error')}</Typography>
                            </Box>
                          </ColoredBox>
                        </Box>
                      ) : (
                        <Grid container alignItems="center" justify="space-between">
                          <Grid item sm={8}>
                            <Subtext>
                              All of your image and video posts will be uploaded and synced with Cozmos.
                            </Subtext>
                          </Grid>
                          <Box pt={3} display="flex" flex="1" justifyContent="flex-end">
                            {socialMedia.syncStatus === ShoppingProviderStatus.success &&
                              <StatusComponent
                                style={{display: 'inline-block'}}
                                status={ShoppingProviderStatus.success}
                                statusText="Sync complete"
                              />
                            }
                            {!socialMedia.syncStatus &&
                              <ButtonComponent text="Start Instagram sync" onClick={handleSync} />
                            }
                          </Box>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
            {socialMedia.syncStatus !== ShoppingProviderStatus.failed && (
              <Grid item>
                <ColoredBox variant="violet with-border">
                  <Box my={5} mx={2}>
                    <Typography variant="body2" className="inherit">{getT('guidance')}</Typography>
                  </Box>
                </ColoredBox>
              </Grid>
            )}
          </Grid>
        </CardComponent>
        <IFrameStylesComponent
          socialMediaSettings={socialMedia as SocialContentInterface}
          setSocialMediaSettings={setSocialMedia}
        />
        <ErrorsLogComponent notificationMessages={notificationMessages} />
      </ValidatorForm>
    </>
  )
}

export default InstagramIntegration;
