import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty, isEqual, pick } from 'lodash';
import { Trans } from 'react-i18next';

import { CardComponent, PageHeader, SettingsForm, ShoppingProviderHint } from 'components';
import { LINKS } from 'components/constants';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { ShopifyDisconnectDialog } from 'components/Dialogs/ShopifyDisconnectDialog/ShopifyDisconnectDialog';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { getTWithPathKey } from 'utils';
import { ROUTES } from 'routes/constants';
import { ClientShoppingProvider, DataServerErrors, NotificationItem } from 'types';
import { useEcommercePlatformsActions, useEcommercePlatformsState } from 'contexts/EcommercePlatformsContext';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { useShoppingProviderStatus } from 'contexts/ShoppingProviderContext';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { authModel, shoppingModel } from 'models';

import { SyncStatusComponent } from '../ShopifyProvider/SyncStatusComponent';
import { defaultSettings } from '../ShopifyProvider/constants';
import { ErrorsLogComponent } from '../ShopifyProvider/ErrorsLogComponent';
import { EcommerceProviderBlocker } from '../ShopifyProvider/EcommerceProviderBlocker';

const IdealProvider = ({
  providerName
}: { providerName: string }) => {
  const { shouldBlock, refreshStatus } = useShoppingProviderStatus();
  const [initialClientShoppingProvider, setInitialClientShoppingProvider] =
    useState<ClientShoppingProvider>(defaultSettings);
  const [clientShoppingProvider, setClientShoppingProvider] =
    useState<ClientShoppingProvider>(defaultSettings);
  const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(false);
  const [isSyncLoading, setSyncLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [notificationMessages, setNotificationMessages] = useState<NotificationItem[]>([]);
  const { handleServerError } = useServerErrorActions();
  const { providers } = useEcommercePlatformsState();
  const { getProviders } = useEcommercePlatformsActions();
  const {
    currentUser: { attributes },
  } = useAuthState();
  const { updateUserData } = useAuthActions();
  const getT = getTWithPathKey('pages.ecommerceProvider');
  const providerId = providers[providerName];
  const isLoading = isInitialLoading || isSyncLoading;
  const useAnotherProvider = attributes.shoppingPlatformEnabled && attributes.shoppingPlatformId !== providerId;

  useEffect(() => {
    if (providers[providerName]) {
      getData();
    } else {
      getProviders();
    }
  }, [providers]);

  useEffect(() => {
    if (title) {
      fetchNotificationMsgs();
    }
  }, [title]);

  const getData = async (withLoading: boolean = true) => {
    if (withLoading) {
      setInitialLoading(true);
    }

    await shoppingModel
      .getShoppingProvider(providerId)
      .then(data => {
        const settings = isEmpty(data.clientShoppingProvider) ? defaultSettings : data.clientShoppingProvider;

        setTitle(data.title);
        setInitialClientShoppingProvider(settings);
        setClientShoppingProvider(settings);
      })
      .catch(handleServerError)
      .finally(() => {
        setInitialLoading(false);
      });
  };

  const fetchNotificationMsgs = () =>
    shoppingModel.getMessagesLog({ title }).then(setNotificationMessages).catch(handleServerError);

  const updateUserInfo = async () => authModel.verifyToken().then(updateUserData);

  const getRequest = () =>
    clientShoppingProvider.id
      ? shoppingModel.putShoppingProvider(
        providerId,
        pick(clientShoppingProvider, [
          'apiKey',
          'shopName',
        ]) as ClientShoppingProvider,
      )
      : shoppingModel.postShoppingProvider(
        providerId,
        pick(clientShoppingProvider, [
          'apiKey',
          'shopName',
        ]) as ClientShoppingProvider,
      );

  const handleSave = async () => {
    try {
      setSyncLoading(true);
      const data = await getRequest();

      if (!data.clientShoppingProvider.connected) {
        await fetchNotificationMsgs();
      } else {
        await updateUserInfo();
      }

      setInitialClientShoppingProvider(data.clientShoppingProvider);
      setClientShoppingProvider(data.clientShoppingProvider);
    } catch (e) {
      handleServerError(e as DataServerErrors<string[]>);
    } finally {
      setSyncLoading(false);
    }
  };

  const handleChange = (item: Partial<ClientShoppingProvider>) =>
    setClientShoppingProvider(prev => ({ ...prev, ...item }));

  const handleResync = () =>
    shoppingModel
      .startReSynchronization(providerId, clientShoppingProvider.id!, { 'resync_all': true })
      .then(getData)
      .catch(handleServerError);

  const handleDisconnect = async (clientName: string) => {
    try {
      setIsDeleteLoading(true);

      await shoppingModel.disconnectStore(providerId, clientShoppingProvider.id!, clientName);
      await refreshStatus();
      await getData(false);
      await updateUserInfo();
    } catch (e) {
      handleServerError(e as ServerErrorType);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const isSaveButtonVisible = () => !isEqual(initialClientShoppingProvider, clientShoppingProvider);

  return (
    <>
      {shouldBlock && <EcommerceProviderBlocker />}
      <PageHeader title="Ideal" backLink={ROUTES.ecommerceProviders} />
      <SettingsForm
        isSaveButtonVisible={isSaveButtonVisible()}
        handleSave={handleSave}
        confirmMessage={undefined}
      >
        {useAnotherProvider && providerId && (
          <ShoppingProviderHint
            connectedStore={attributes.shoppingPlatformName}
            currentStore={title}
          />
        )}
        <CardComponent
          isLoading={isLoading || useAnotherProvider}
          useSpinner={!useAnotherProvider}
          title={getT('ideal360.title', { title })}
          xs={9}
        >
          <Grid container>
            <Grid item sm={12}>
              <Box mt={5} mb={6}>
                <Typography className="information">
                  <Trans
                    i18nKey={getT('ideal360.caption', { title })}
                    components={{ a: <a href={LINKS.ecommerceIntegration} target="_blank" rel="noreferrer" /> }}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required]}
                label={`${title} Store Name`}
                value={clientShoppingProvider.shopName}
                fieldName="shopName"
                autoComplete="organization"
                handleChange={handleChange}
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            <Grid item sm={4}>
              <Box display="flex" alignItems="center" ml={5} mt={3}>
                {clientShoppingProvider.connected && (
                  <>
                    <StatusComponent statusText={getT('successConnect')} status={UPLOAD_STATUSES.SUCCESS} />
                    <StatusComponent
                      statusText="Disconnect"
                      status={UPLOAD_STATUSES.ERROR}
                      onClick={() => setOpenDisconnectDialog(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required]}
                label="API key"
                value={clientShoppingProvider.apiKey}
                fieldName="apiKey"
                handleChange={handleChange}
                type="password"
                autoComplete="new-password"
                disabled={clientShoppingProvider.connected}
              />
            </Grid>
            {clientShoppingProvider.connected && (
              <SyncStatusComponent
                providerName={providerName}
                providerId={providerId}
                clientShoppingProvider={clientShoppingProvider}
                getShoppingProvider={getData}
                handleServerError={handleServerError}
                handleToggleDialog={handleResync}
              />
            )}
          </Grid>
        </CardComponent>
      </SettingsForm>
      {openDisconnectDialog && clientShoppingProvider.id && (
        <ShopifyDisconnectDialog
          storeName={title}
          onClose={() => setOpenDisconnectDialog(false)}
          onSubmit={handleDisconnect}
          loading={isDeleteLoading}
        />
      )}
      <ErrorsLogComponent notificationMessages={notificationMessages} />
    </>
  );
};

export default IdealProvider;
