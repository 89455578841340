import React from 'react';

import { Box } from '@material-ui/core';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { Typography } from './style';

interface Settings {
  primaryColor: string;
  secondaryColor: string;
}

interface OverlayColorsComponentProps {
  settings: Settings;
  onChange: (settings: Settings) => void;
}

export const OverlayColorsComponent = ({ settings, onChange }: OverlayColorsComponentProps) => (
  <>
    <Box mb={4}>
      <Typography variant="h4" display="inline">
        Overlay UI
      </Typography>
    </Box>
    <Box>
      <Box mb={2}>
        <Typography variant="caption">Primary overlay colour</Typography>
      </Box>
      <ColorPicker
        setColor={({ hex }) => onChange({ ...settings, primaryColor: hex })}
        color={{ hex: settings.primaryColor }}
      />
    </Box>
    <Box mt={5}>
      <Box mb={2}>
        <Typography variant="caption">Secondary overlay colour (info tab)</Typography>
      </Box>
      <ColorPicker
        setColor={({ hex }) => onChange({ ...settings, secondaryColor: hex })}
        color={{ hex: settings.secondaryColor }}
      />
    </Box>
  </>
);
