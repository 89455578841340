import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core';

import { ROUTES } from 'routes/constants';
import { useShoppingProviderBlocker } from 'contexts/ShoppingProviderContext';
import { useAuthState } from 'contexts/AuthContext';
import { ButtonComponent } from 'components';

import { Dialog, DialogContent, DialogTitle } from 'components/Dialogs/style';

export function EcommerceProviderBlocker() {
  const knownStatus = useShoppingProviderBlocker();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    currentUser: { attributes: { shoppingPlatformName } },
  } = useAuthState();

  const close = () => history.push(ROUTES.settings);

  if (!knownStatus)
    return (
      <Dialog open>
        <CircularProgress color="primary" />
      </Dialog>
    );

  return (
    <Dialog open>
      <DialogTitle>{t('pages.ecommerceProvider.blockingDialog.title')}</DialogTitle>
      <DialogContent>
        <Trans components={{ br: <br /> }} i18nKey="pages.ecommerceProvider.blockingDialog.content" values={{storeName: shoppingPlatformName}} />
      </DialogContent>
      <Box my={4}>
        <LinearProgress color="primary" />
      </Box>
      <Grid container justify="flex-end">
        <ButtonComponent color="primary" onClick={close} text={t('pages.ecommerceProvider.blockingDialog.close')} />
      </Grid>
    </Dialog>
  );
}
