import { Box as MuiBox, styled } from '@material-ui/core';
import { InvertColors } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';
import { getHexadecimal } from 'utils';

import overlayImage from 'assets/img/overlay-preview-image.png';

export const WrapperContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '320px',
  borderRadius: '6px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const BackgroundImageContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${overlayImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  zIndex: 1,
  borderRadius: '6px',
});

interface OverlayPreviewContainerProps {
  backgroundcolor: string;
  opacity: number;
  blur: number;
}

export const OverlayPreviewContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: ({ backgroundcolor, opacity }: OverlayPreviewContainerProps) =>
    `${backgroundcolor}${getHexadecimal(opacity * 100)}`,
  backdropFilter: ({ blur }) => `blur(${blur * 25}px)`,
  webkitBackdropFilter: ({ blur }) => `blur(${blur * 25}px)`,
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
});

export const PreviewBox = styled(MuiBox)({
  position: 'relative',
  '&.primary': {
    width: '70%',
    maxWidth: '584px',
    height: '160px',
    position: 'relative',
    padding: '34px 20px',
    marginTop: '28px',
    borderRadius: '6px',
  },
  '&.secondary': {
    height: '132px',
    width: '50%',
    position: 'absolute',
    top: '14px',
    right: 0,
    padding: '22px 20px',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
  },
});

export const CloseButtonWrapper = styled('div')({
  position: 'absolute',
  top: '-86px',
  right: '-56px',
  zIndex: 3,
});

export const TitleBox = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  color: COLORS.white,
});

export const InvertColorsIcon = styled(InvertColors)({
  height: ICON_SIZE.regular,
  width: ICON_SIZE.regular,
  marginRight: '6px',
});

export const ScrollbarArea = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '6px',
  height: 'calc(100% - 4px)',
  margin: '2px',
});

export const ScrollbarTrack = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: '3px',
});

export const ScrollbarHandle = styled('div')({
  height: '20%',
  borderRadius: '3px',
});
