import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { AuthModelFields } from 'models/AuthModel';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface ShopifyDisconnectDialogProps {
  storeName: string;
  loading: boolean;
  onClose: () => void;
  onSubmit: (clientName: string) => void;
}

const SPACE_NAME = 'space_name';

export function ShopifyDisconnectDialog({ storeName, loading, onClose, onSubmit }: ShopifyDisconnectDialogProps) {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [client, setClient] = useState('');

  const handleChange = (value: { [SPACE_NAME]: string }) => setClient(value[SPACE_NAME]);

  const renderConfirmation = () => (
    <>
      <DialogContent>
        <DialogContentText style={{ paddingLeft: 0 }}>
          <Trans
            i18nKey="components.dialogs.shopifyDisconnect.confirmationMessage"
            values={{ storeName }}
          />
        </DialogContentText>
        <ValidatorForm onSubmit={() => onSubmit(client)} autoComplete="off">
          <TextValidatorComponent
            validators={[VALIDATORS_MAP.required]}
            label="Space Name"
            value={client}
            fieldName={SPACE_NAME}
            handleChange={handleChange}
            autoFocus
          />
          <DialogActions>
            <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
            <ButtonComponent
              text={t('components.dialogs.shopifyDisconnect.confirmBtn')}
              type="submit"
              color="primary"
              disabled={!client}
              loaderSize="1.35em"
              loading={loading}
            />
          </DialogActions>
        </ValidatorForm>
      </DialogContent>
    </>
  );

  const renderContent = () => (
    <DialogContent>
      <Typography component="span">
        <Trans
          i18nKey="components.dialogs.shopifyDisconnect.message"
          components={{
            ul: <ul />,
            li: <li />,
          }}
          values={{storeName}}
        />
      </Typography>
      <DialogActions>
        <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
        <ButtonComponent
          text={t('components.dialogs.shopifyDisconnect.continueBtn')}
          color="primary"
          onClick={() => setShowConfirmation(true)}
        />
      </DialogActions>
    </DialogContent>
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Trans
          i18nKey="components.dialogs.shopifyDisconnect.title"
          values={{ storeName }}
        />
      </DialogTitle>
      {showConfirmation ? renderConfirmation() : renderContent()}
    </Dialog>
  );
}
