import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import { CardComponent, PageHeader, SettingsProviderButton } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { shoppingModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { ShoppingProviderTypes } from 'types/enums';

import { PROVIDERS_ICONS_MAP } from './constants';

export default function EcommerceProviders() {
  const [shoppingProviders, setShoppingProviders] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const getT = getTWithPathKey('pages.settings.ecommerceProviders');

  useEffect(() => {
    getShoppingProviders();
  }, []);

  const getShoppingProviders = withPageProgressHandler(() =>
    shoppingModel.getShoppingProviders().then(setShoppingProviders).catch(handleServerError)
  );

  return (
    <>
      <PageHeader title={getT('title')} backLink={ROUTES.settings} />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Box display="flex">
            {shoppingProviders.map(({ title }) => (
              <SettingsProviderButton
                key={title}
                Icon={PROVIDERS_ICONS_MAP[title]}
                title={title}
                fullWidth={title.toLowerCase() === ShoppingProviderTypes.IDEAL}
                to={`${ROUTES.ecommerceProviderSettingsNamespace}${title.toLowerCase()}`}
              />
            ))}
          </Box>
        </CardComponent>
      </Grid>
    </>
  );
}
