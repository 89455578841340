import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Box } from './style';

interface ShoppingProviderHintProps {
  currentStore: string | null,
  connectedStore: string | null
}

export const ShoppingProviderHint = ({
  currentStore,
  connectedStore,
}: ShoppingProviderHintProps) => (
  <Grid item xs={9} sm={8} style={{ position: 'relative' }}>
    <Box display="flex" justifyContent="center">
      <InfoOutlinedIcon />
      <Typography color="inherit">
        <Trans
          i18nKey="components.shoppingProviders.message"
          components={{ b: <b /> }}
          values={{ currentStore, connectedStore }}
        />
      </Typography>
    </Box>
  </Grid>
);
