import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { PageHeader, PageHeaderNav, CardComponent } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { overlaysStylingModel } from 'models';
import { ROUTES } from 'routes/constants';
import { withSuccessActionsDialog } from 'utils/enhancers';
import { OverlayPreviewSettings } from 'components/OverlayPreviewSettings/OverlayPreviewSettings';
import { OverlayStylingUpdateParams } from 'types';

interface OverlaysSettingsProps {
  setSuccessActionMessages: (success: string[]) => void;
}

const OverlaysSettings = ({ setSuccessActionMessages }: OverlaysSettingsProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<OverlayStylingUpdateParams & { id: number }>();
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getOverlayStying();
  }, []);

  const getOverlayStying = withPageProgressHandler(() =>
    overlaysStylingModel.getOverlaysStyling().then(stylings => setState(stylings)),
  );

  const onSaveStylings = withPageProgressHandler(() => {
    if (state !== undefined)
      return overlaysStylingModel
        .updateOverlaysStyling(state, state.id)
        .then(data => setSuccessActionMessages(data.success))
        .catch(handleServerError);
    return Promise.resolve();
  });

  if (state === undefined) return null;

  return (
    <ValidatorForm onSubmit={onSaveStylings}>
      <PageHeader title={t('pages.settings.overlaysSettings.title')} backLink={ROUTES.settings}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      <Grid container>
        <CardComponent title="Overlays colour style">
          <OverlayPreviewSettings
            settings={{
              primaryColor: state.primaryColor,
              secondaryColor: state.secondaryColor,
              iframePrimaryColor: state.iframePrimaryColor,
              iframeSecondaryColor: state.iframeSecondaryColor,
              backgroundColor: state.backgroundColor,
              opacity: state.opacity,
              blur: state.blur,
            }}
            onChange={updatedSettings =>
              setState({
                ...state,
                primaryColor: updatedSettings.primaryColor,
                secondaryColor: updatedSettings.secondaryColor,
                iframePrimaryColor: updatedSettings.iframePrimaryColor,
                iframeSecondaryColor: updatedSettings.iframeSecondaryColor,
                backgroundColor: updatedSettings.backgroundColor,
                opacity: updatedSettings.opacity,
                blur: updatedSettings.blur,
              })
            }
          />
        </CardComponent>
      </Grid>
    </ValidatorForm>
  );
};

export default withSuccessActionsDialog(OverlaysSettings);
