import React, { memo, useEffect, useState } from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CardComponent } from 'components/CardComponent/CardComponent';
import { ImageUploader } from 'components/ImageUploader/ImageUploader';
import { LINKS } from 'components/constants';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { picturesModel, tagsModel } from 'models';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

function ImagesListComponent({
  activeTag,
  handleUpdateTags,
  shouldUpdatePictures,
  setImageCount,
  handleServerError,
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
  addImageToTag,
  setUntaggedAssetsCount,
}) {
  const { t } = useTranslation();

  const [images, setImages] = useState([]);
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getPictures(activeTag.id);
  }, [activeTag, paginationParams, shouldUpdatePictures]);

  const getPictures = withPageProgressHandler(tagId =>
    picturesModel
      .getPictures({ ...paginationParams, tagId, untagged: activeTag?.untagged })
      .then(({ pictures, picturesCount, untaggedCount }) => {
        setImages(pictures);
        setPaginationItemsCount(picturesCount);
        setImageCount(picturesCount);
        setUntaggedAssetsCount(untaggedCount);
      })
      .catch(handleServerError),
  );

  const loadImage = (image, tagId) => {
    const signedId = get(image, 'signed_id');

    return picturesModel.addPicture({ signed_id: signedId }).then(picture => {
      resetPaginationPage();
      tagId ? addImageToTag(tagId, [picture.id]) : getPictures();
    });
  };

  const unloadImage = (imageId, tagId) =>
    picturesModel
      .deletePicture(imageId)
      .then(() => {
        handleUpdateTags();
        getPictures(tagId);
      })
      .catch(handleServerError);

  const handleEditImage = (id, data) =>
    picturesModel
      .editPicture(id, data)
      .then(() => getPictures(activeTag.id))
      .catch(handleServerError);

  const onDeleteAllImages = withPageProgressHandler(imageIds => {
    const actions = imageIds.map(id =>
      picturesModel
        .deletePicture(id)
        .catch(handleServerError)
    );

    return Promise.allSettled(actions).then(() => {
      getPictures(activeTag.id);
      handleUpdateTags();
    });
  });

  const onRemoveTagsFromImage = selectedImagesIds =>
    tagsModel
      .deleteAsset({ id: activeTag.id, tag: { pictureIds: [...selectedImagesIds] } })
      .then(() => {
        handleUpdateTags();
        getPictures(activeTag.id);
      })
      .catch(handleServerError);

  return (
    <CardComponent sm={12}>
      <ImageUploader
        images={images}
        loadImage={loadImage}
        unloadImage={unloadImage}
        activeTag={activeTag}
        handleServerError={handleServerError}
        selectButtonText="Detach from Tag"
        handleSelectComplete={onRemoveTagsFromImage}
        handleDeleteAll={onDeleteAllImages}
        withEdit
        handleEdit={handleEditImage}
        section={DOWNLOAD_SECTIONS.images}
        withUploadButton
        withTags
        addAssetsToTag={addImageToTag}
        uploadButtonText={t('components.imageUploader.uploadPNG')}
        text={
          <span>
            {t('pages.images.imageUploaderText')}
            <a href={LINKS.sphereKnowledgeBase} target="_blank" rel="noreferrer">
              {t('pages.images.imageUploaderTextLink')}
            </a>
          </span>
        }
        dropzoneText={t('pages.images.dropzoneText')}
      />
    </CardComponent>
  );
}

ImagesListComponent.propTypes = {
  ...withPaginationPropTypes,
  activeTag: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  handleUpdateTags: PropTypes.func.isRequired,
  shouldUpdatePictures: PropTypes.bool.isRequired,
  setImageCount: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  addImageToTag: PropTypes.func.isRequired,
  setUntaggedAssetsCount: PropTypes.func,
};

export const ImagesList = memo(withPagination(ImagesListComponent));
