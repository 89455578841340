import React, { useState } from 'react';

import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CardComponent, SettingsForm } from 'components';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { OverlayPreviewSettings } from 'components/OverlayPreviewSettings/OverlayPreviewSettings';
import { PlanogramVersion, PlanogramVersionUpdateParams } from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';

import { Switch } from 'components/style';
import { HeaderHelpText } from '../style';

type PlanogramOverlaySettings = Pick<
  PlanogramVersion,
  | 'enabledOverlayColors'
  | 'primaryColor'
  | 'secondaryColor'
  | 'iframePrimaryColor'
  | 'iframeSecondaryColor'
  | 'backgroundColor'
  | 'opacity'
  | 'blur'
>;

const getPlanogramOverlaySettings = (planogramVersion: PlanogramVersion): PlanogramOverlaySettings => ({
  enabledOverlayColors: planogramVersion.enabledOverlayColors,
  primaryColor: planogramVersion.primaryColor,
  secondaryColor: planogramVersion.secondaryColor,
  iframePrimaryColor: planogramVersion.iframePrimaryColor,
  iframeSecondaryColor: planogramVersion.iframeSecondaryColor,
  backgroundColor: planogramVersion.backgroundColor,
  opacity: planogramVersion.opacity,
  blur: planogramVersion.blur,
});

interface CustomOverlaySettingsProps {
  planogramId: number;
  planogramVersion: PlanogramVersion;
  setSuccessActionMessages: (success: string[]) => void;
}

export function CustomOverlaySettings({
  planogramId,
  planogramVersion,
  setSuccessActionMessages,
}: CustomOverlaySettingsProps) {
  const [initialSettings, setInitialSettings] = useState<PlanogramOverlaySettings>(
    getPlanogramOverlaySettings(planogramVersion),
  );
  const [settings, setSettings] = useState<PlanogramOverlaySettings>(getPlanogramOverlaySettings(planogramVersion));
  const { t } = useTranslation();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const { handleServerError } = useServerErrorActions();

  const onSubmit = withHandlingSubmittingState(async () =>
    planogramsModel
      .editVersion(planogramId, planogramVersion.id, settings as Partial<PlanogramVersionUpdateParams>)
      .then(data => {
        const updatedSettings = getPlanogramOverlaySettings(data.planogramVersion);

        setSettings(updatedSettings);
        setInitialSettings(updatedSettings);
        setSuccessActionMessages(data.success);
      })
      .catch(handleServerError),
  );

  const isSaveButtonVisible = !isSubmitting && !isEqual(settings, initialSettings);

  const renderTitle = () => (
    <>
      {t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.title')} &nbsp;
      <HeaderHelpText>{t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.titleHint')}</HeaderHelpText>
    </>
  );

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={onSubmit}>
      <CardComponent
        title={renderTitle()}
        isLoading={isSubmitting}
        CardHeaderNav={() => (
          <Switch
            checked={settings.enabledOverlayColors}
            onChange={() => setSettings(prev => ({ ...prev, enabledOverlayColors: !prev.enabledOverlayColors }))}
          />
        )}
      >
        {settings.enabledOverlayColors && (
          <OverlayPreviewSettings
            settings={{
              primaryColor: settings.primaryColor,
              secondaryColor: settings.secondaryColor,
              iframePrimaryColor: settings.iframePrimaryColor,
              iframeSecondaryColor: settings.iframeSecondaryColor,
              backgroundColor: settings.backgroundColor,
              blur: settings.blur,
              opacity: settings.opacity,
            }}
            onChange={updatedSettings =>
              setSettings({
                ...settings,
                ...updatedSettings,
              })
            }
          />
        )}
      </CardComponent>
    </SettingsForm>
  );
}
